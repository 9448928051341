/* eslint-disable no-param-reassign */
import lodash from 'lodash';
import {createSlice} from '@reduxjs/toolkit';

import {
    getUsers, inviteUser, updateUser, deleteUser
} from '../api/usersThunk';

const setPendingState = (state) => {
    const status = {
        loading: true,
        updated: false,
        deleted: false,
        invited: false
    };
    state.status = lodash.merge(state.status, status);
    state.error = null;
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false,
        updated: false,
        deleted: false,
        invited: false
    };
    state.status = lodash.merge(state.status, status);
    state.error = action.error.message;
};

const setGetFulfilledState = (state) => {
    const status = {
        loading: false,
        updated: false,
        deleted: false,
        invited: false
    };
    state.status = lodash.merge(state.status, status);
    state.error = null;
};


const initialState = {
    error: null,
    status: {
        loading: false,
        updated: false,
        deleted: false,
        invited: false
    },
    users: []
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.fulfilled, (state, action) => {
                setGetFulfilledState(state);
                state.users = action.payload;
            })
            .addCase(inviteUser.fulfilled, (state) => {
                setGetFulfilledState(state);
                state.status.invited = true;
            })
            .addCase(updateUser.fulfilled, (state) => {
                state.status.loading = false;
                state.status.updated = true;
                state.status.deleted = false;
                state.status.invited = false;
                state.error = null;
            })
            .addCase(deleteUser.fulfilled, (state) => {
                state.status.loading = false;
                state.status.updated = false;
                state.status.deleted = true;
                state.status.invited = false;
                state.error = null;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('users'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('users'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export default usersSlice.reducer;
