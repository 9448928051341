import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';
import omitEmpty from '../utils/omitEmpty';

export const fetchAllKits = createAsyncThunk(
    'kit/fetchAllKits',
    async () => fetchRequest(
        '/me/kits',
        'GET'
    )
);

export const fetchKit = createAsyncThunk(
    'kit/fetchKit',
    async (id) => fetchRequest(
        `/me/kits/${id}`,
        'GET'
    )
);

export const createKit = createAsyncThunk(
    'kit/createKit',
    async ({
        contractId,
        transformitReleaseId,
        transformitReleaseName,
        quantity,
        cipher,
        targetPlatform,
        publicKey,
        publicKeyFingerprint,
        fingerprintFileName,
        message,
        backwardCompatibility,
        customCipher,
        customTargetPlatform
    }, thunkAPI) => {
        const response = await fetchRequest(
            `/me/application/${contractId}/kits`,
            'POST',
            null,
            omitEmpty({
                transformitReleaseId,
                transformitReleaseName,
                quantity,
                cipher,
                targetPlatform,
                publicKey,
                publicKeyFingerprint,
                fingerprintFileName,
                message,
                backwardCompatibility,
                customCipher,
                customTargetPlatform
            })
        );
        thunkAPI.dispatch(fetchAllKits());
        return response;
    }
);

export const fetchCiphers = createAsyncThunk(
    'kit/fetchCiphers',
    async (contractId) => fetchRequest(
        `/me/application/${contractId}/transformit/cipher`,
        'GET'
    )
);

export const fetchPlatforms = createAsyncThunk(
    'kit/fetchPlatforms',
    async (contractId) => fetchRequest(
        `/me/application/${contractId}/transformit/os`,
        'GET'
    )
);

export const fetchAllPlatforms = createAsyncThunk(
    'kit/fetchAllPlatforms',
    async (appId) => fetchRequest(
        `/me/applications/${appId}/transformit/platforms`,
        'GET'
    )
);

export const fetchContracts = createAsyncThunk(
    'kit/fetchContracts',
    async () => fetchRequest(
        `/me/transformit/applications`,
        'GET'
    )
);

export const updateStatus = createAsyncThunk(
    'kit/updateStatus',
    async ({kitId, status}, thunkAPI) => {
        await fetchRequest(
            `/kits/${kitId}`,
            'PUT',
            null,
            omitEmpty({
                status
            })
        );
        thunkAPI.dispatch(fetchAllKits());
    }
);

export const checkAvailability = createAsyncThunk(
    'kit/checkAvailability',
    async (contractId) => fetchRequest(
        `/me/transformit/${contractId}`,
        'GET'
    )
);

export const getDownloadAccessCode = async ({kitId, uploadId}) => fetchRequest(
    `/downloads/kits/${kitId}/uploads/${uploadId}/downloadAccessCode`,
    'POST'
);

export const getKitUploads = createAsyncThunk(
    'kit/getKitUploads',
    async (kitId) => fetchRequest(
        `/kits/${kitId}/uploads`,
        'GET'
    )
);

export const deleteKitUpload = createAsyncThunk(
    'kit/deleteKitUpload',
    async ({kitId, uploadId}, thunkAPI) => {
        await fetchRequest(
            `/kits/${kitId}/uploads/${uploadId}`,
            'DELETE'
        );
        thunkAPI.dispatch(getKitUploads(kitId));
    }
);
