import React from 'react';
import ReactDOM from 'react-dom';
import {DotSnackbarProvider, DotThemeProvider} from '@digital-ai/dot-components';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import store from './stores/store';
import {AuthenticationProvider} from './providers/AuthenticationProvider';
import routes from './routes';
import GlobalStyles from './global/global.styles';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <DotThemeProvider>
                    <GlobalStyles/>
                    <DotSnackbarProvider>
                        <AuthenticationProvider>
                            {routes}
                        </AuthenticationProvider>
                    </DotSnackbarProvider>
                </DotThemeProvider>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('app')
);
