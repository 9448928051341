import {createAsyncThunk} from '@reduxjs/toolkit';
import omitEmpty from '../utils/omitEmpty';
import fetchRequest from './utils';
import * as demoResponses from './demoResponses/appAwareDashboard';

export const getDefinitions = createAsyncThunk('appAware/getDefinitions', async ({companyId}) => fetchRequest('/threats/definitions', 'GET', omitEmpty({companyId})));

export const getNotificationConfiguration = createAsyncThunk('appAware/getNotificationConfiguration', async ({applicationId, companyId}) => fetchRequest('/threat-analytics/applicationProperties', 'GET', omitEmpty({label: 'notifications', applicationId, companyId})));

export const getEvents = createAsyncThunk('appAware/getEvents', async (params) => fetchRequest('/threats/events', 'GET', omitEmpty({
    companyId: params.companyId,
    start: params.start
})));

export const getDashboardData = createAsyncThunk('appAware/getDashboardData', async (params) => {
    const query = omitEmpty({
        companyId: params.companyId,
        start: params.start
    });
    return fetchRequest('/threats/health', 'POST', query);
});

export const getTable = createAsyncThunk('appAware/getTable', async (params) => {
    const query = omitEmpty({
        companyId: params.companyId,
        limit: params.limit,
        page: params.page,
        queryExecutionId: params.queryExecutionId,
        nextToken: params.nextToken
    });
    return fetchRequest('/threats/table', 'POST', query, {filter: params.filter});
});

export const updateNotificationConfiguration = createAsyncThunk('appAware/updateNotificationConfiguration', async (data) => {
    const {
        companyId,
        applicationId,
        ...other
    } = data;
    return fetchRequest('/threat-analytics/applicationProperties', 'POST', omitEmpty({applicationId, companyId, label: 'notifications'}), other);
});

// mock thunk that return a response with demo data
export const getMockDashboardData = createAsyncThunk('appAware/getDashboardData', async (responseName) => demoResponses[responseName]);
