/* eslint-disable no-param-reassign */
import lodash from 'lodash';
import {createSelector, createSlice} from '@reduxjs/toolkit';

import {
    initReleaseUpload, initKitUpload, initShareUpload, upload, updateReleaseFileType, updateKitFileType
} from '../api/uploadManagerThunk';

const setPendingState = (state) => {
    const status = {
        loading: true,
        updated: false
    };
    state.status = lodash.merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false,
        updated: false
    };
    state.status = lodash.merge(state.status, status);
    state.error = action.error.message;
};

const setGetFulfilledState = (state) => {
    const status = {
        loading: false,
        updated: false
    };
    state.status = lodash.merge(state.status, status);
};

const initialState = {
    error: null,
    status: {
        loading: false,
        updated: false
    },
    initShareUploadData: null,
    initKitUploadData: null,
    initReleaseUploadData: null
};

const uploadManagerSlice = createSlice({
    name: 'uploadManager',
    initialState,
    reducers: {
        resetReleaseUploadData: (state) => {
            state.initReleaseUploadData = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(initReleaseUpload.fulfilled, (state, action) => {
                setGetFulfilledState(state, action);
                state.initReleaseUploadData = action.payload;
            })
            .addCase(initKitUpload.fulfilled, (state, action) => {
                setGetFulfilledState(state, action);
                state.initKitUploadData = action.payload;
            })
            .addCase(initShareUpload.fulfilled, (state, action) => {
                setGetFulfilledState(state, action);
                state.initShareUploadData = action.payload;
            })
            .addCase(upload.fulfilled, (state, action) => {
                setGetFulfilledState(state, action);
            })
            .addCase(updateReleaseFileType.fulfilled, (state, action) => {
                setGetFulfilledState(state, action);
            })
            .addCase(updateKitFileType.fulfilled, (state, action) => {
                setGetFulfilledState(state, action);
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('upload'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('upload'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

const isUploadingLogic = (data, type) => lodash.get(data, ['isUploading', type]);
const getCurrentUploadDataLogic = (data, type) => lodash.get(data, `current.${type}`, {});

export const getInstances = createSelector((state) => state.uploadManagerReducer.data, (data) => lodash.omit(data, ['status', 'files', 'current']));
export const isUploading = createSelector((state) => state.uploadManagerReducer.data, isUploadingLogic);
export const getWaitingFile = createSelector((state) => state.uploadManagerReducer.data, (data) => lodash.get(data, 'files', []).pop());
export const hasWaitingFiles = createSelector((state) => state.uploadManagerReducer.data, (data) => lodash.get(data, 'files', []).length > 0);
export const getCurrentUploadData = createSelector((state) => state.uploadManagerReducer.data, getCurrentUploadDataLogic);
export const getCurrentUploadAppId = createSelector((state) => state.uploadManagerReducer.data, (data, type) => (isUploadingLogic(type) ? getCurrentUploadDataLogic(type).id : null));

export const {resetReleaseUploadData} = uploadManagerSlice.actions;
export default uploadManagerSlice.reducer;
