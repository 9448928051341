/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit';

import {
    getTokens, generateToken, deleteToken, deleteUserToken
} from '../api/tokenThunk';

const setFulfilledState = (state) => {
    state.status.loading = false;
    state.status.deleted = false;
    state.status.generated = false;
    state.error = null;
};

const initialState = {
    error: null,
    status: {
        loading: false,
        deleted: false
    },
    tokens: []
};

const tokenSlice = createSlice({
    name: 'tokens',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTokens.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.tokens = action.payload;
            })
            .addCase(generateToken.fulfilled, (state) => {
                state.status.generated = true;
            })
            .addCase(deleteUserToken.fulfilled, (state) => {
                state.status.deleted = true;
            })
            .addCase(deleteToken.fulfilled, (state) => {
                state.status.deleted = true;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('tokens'), (state) => {
                state.status.loading = true;
                state.status.deleted = false;
                state.status.generated = false;
                state.error = null;
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('tokens'), (state, action) => {
                state.status.loading = false;
                state.status.deleted = false;
                state.status.generated = false;
                state.error = action.error.message;
            });
    }
});

export const getUserToken = (tokens, email) => tokens.find((token) => token.email === email);

export default tokenSlice.reducer;
