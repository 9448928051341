import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getCompanies = createAsyncThunk('companies/getCompanies', async (params) => fetchRequest('/companies', 'GET', params));

export const getCompaniesWithContracts = createAsyncThunk('companies/getCompaniesWithContracts', async (params) => fetchRequest('/companies/contracts', 'GET', params));

export const getEntitledCompanies = createAsyncThunk('companies/getEntitledCompanies', async (productKeys) => fetchRequest(`/companies/entitled`, 'POST', null, {productKeys}));

export const updateCompany = createAsyncThunk('companies/updateCompany',
    async (body) => {
        const {id, ...data} = body;
        return fetchRequest(`/companies/${id}`, 'PUT', null, data);
    });

export const deleteCompany = createAsyncThunk('companies/deleteCompany', async (id) => fetchRequest(`/companies/${id}`, 'DELETE', null));
