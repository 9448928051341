// two demo responses so we can test the Dashboard page charts, responses hold different week data from Production.

export const responseOne = {
    meta: {
        start: '2024-05-20T00:00:00.000Z',
        end: '2024-05-20T00:00:00.000Z',
        timestamp: 1716277885994,
        granularity: 2,
        companyId: 458,
        limit: 100,
        page: 0,
        nextToken: null,
        queryExecutionId: null,
        orderBy: 'category',
        order: 'desc'
    },
    data: [
        {
            change: -76.36073787292189,
            count: 1038,
            chartData: [
                51,
                27,
                32,
                20,
                47,
                44,
                53,
                96,
                62,
                52,
                63,
                59,
                61,
                58,
                112,
                60,
                77,
                59,
                67,
                76,
                74,
                69,
                35,
                32,
                71,
                33,
                49,
                36,
                25,
                42,
                59,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            label: 'Weekly Instances',
            type: 'simpleLineChart'
        },
        {
            change: -79.83988355167394,
            count: 277,
            chartData: [
                3,
                2,
                7,
                1,
                22,
                9,
                7,
                8,
                9,
                12,
                23,
                13,
                21,
                14,
                15,
                12,
                3,
                5,
                19,
                35,
                26,
                12,
                5,
                0,
                4,
                3,
                8,
                5,
                4,
                10,
                14,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            label: 'Weekly Compromised Instances',
            type: 'simpleLineChart'
        },
        {
            change: -91.59096750324542,
            count: 102735,
            chartData: [
                1409,
                1544,
                1881,
                1261,
                9161,
                9411,
                1328,
                1484,
                1745,
                3475,
                2005,
                2010,
                1655,
                1606,
                11899,
                2059,
                1768,
                1692,
                1327,
                1272,
                10172,
                1191,
                1374,
                1333,
                1772,
                1203,
                1943,
                1197,
                10211,
                5189,
                6078,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            label: 'Weekly Events',
            type: 'simpleLineChart'
        },
        {
            chartData: {
                '': 5,
                AE: 1,
                AU: 5,
                BR: 19,
                CA: 1,
                CH: 6,
                CN: 1,
                CO: 1,
                DE: 3,
                ES: 2,
                GB: 140,
                HK: 1,
                HR: 2,
                IE: 267,
                IL: 4,
                IN: 15,
                JP: 21,
                KR: 42,
                LT: 14,
                MX: 8,
                NZ: 12,
                PL: 10,
                PT: 2,
                RO: 9,
                SE: 2,
                SG: 1,
                SV: 1,
                TH: 3,
                TW: 30,
                US: 430,
                UY: 1,
                ZA: 12
            },
            label: 'Instances by Location',
            type: 'pie'
        },
        {
            chartData: {
                '': 5,
                AE: 1,
                AU: 4,
                BR: 12,
                CH: 4,
                GB: 92,
                HK: 1,
                IL: 1,
                IN: 8,
                JP: 1,
                KR: 10,
                LT: 10,
                MX: 5,
                PL: 5,
                RO: 5,
                SV: 1,
                TH: 1,
                TW: 7,
                US: 95,
                ZA: 9
            },
            label: 'Compromised Instances by Location',
            type: 'pie'
        },
        {
            count: 277,
            chartData: {
                'Debug Detection': 13,
                'Domain Lock': 6,
                'In-App Firewall': 277
            },
            label: 'Most Active Guards',
            type: 'bar'
        }
    ]
};

export const responseTwo = {
    meta: {
        start: '2024-05-13T00:00:00.000Z',
        end: '2024-05-13T00:00:00.000Z',
        timestamp: 1716277980696,
        granularity: 2,
        companyId: 458,
        limit: 100,
        page: 0,
        nextToken: null,
        queryExecutionId: null,
        orderBy: 'category',
        order: 'desc'
    },
    data: [
        {
            change: -7.440978077571669,
            count: 4391,
            chartData: [
                57,
                53,
                43,
                38,
                55,
                56,
                63,
                206,
                104,
                88,
                116,
                82,
                81,
                98,
                120,
                105,
                112,
                86,
                96,
                71,
                58,
                67,
                64,
                44,
                56,
                43,
                33,
                40,
                43,
                53,
                61,
                181,
                97,
                104,
                78,
                73,
                106,
                101,
                106,
                107,
                131,
                127,
                120,
                80,
                63,
                96,
                93,
                52,
                53,
                30,
                50,
                26,
                51,
                51,
                100,
                220,
                109,
                71,
                93,
                89,
                72,
                108,
                105,
                91,
                89,
                82,
                69,
                85,
                66,
                72,
                55,
                29,
                54,
                42,
                42,
                44,
                55,
                60,
                138,
                179,
                71,
                111,
                76,
                82,
                105,
                75,
                104,
                85,
                85,
                83,
                63,
                81,
                39,
                55,
                49,
                31,
                58,
                44,
                27,
                33,
                42,
                33,
                50,
                104,
                103,
                107,
                53,
                30,
                87,
                101,
                110,
                81,
                95,
                46,
                66,
                65,
                70,
                64,
                46,
                23,
                52,
                29,
                17,
                37,
                6,
                10,
                16,
                43,
                8,
                8,
                20,
                9,
                8,
                15,
                15,
                21,
                10,
                11,
                8,
                7,
                10,
                17,
                13,
                17,
                32,
                16,
                24,
                9,
                8,
                10,
                8,
                49,
                6,
                10,
                21,
                16,
                6,
                21,
                22,
                14,
                9,
                10,
                7,
                10,
                11,
                10,
                11,
                0
            ],
            label: 'Weekly Instances',
            type: 'simpleLineChart'
        },
        {
            change: 107.86686838124055,
            count: 1374,
            chartData: [
                10,
                8,
                5,
                3,
                13,
                6,
                9,
                21,
                15,
                6,
                3,
                15,
                5,
                22,
                16,
                6,
                19,
                15,
                6,
                5,
                3,
                10,
                5,
                3,
                1,
                2,
                5,
                8,
                4,
                11,
                17,
                26,
                18,
                21,
                9,
                15,
                14,
                16,
                23,
                16,
                11,
                13,
                9,
                4,
                7,
                0,
                11,
                5,
                9,
                4,
                8,
                9,
                8,
                14,
                63,
                35,
                24,
                11,
                16,
                41,
                34,
                51,
                10,
                18,
                12,
                31,
                10,
                13,
                9,
                8,
                1,
                1,
                7,
                16,
                14,
                2,
                15,
                21,
                48,
                46,
                16,
                15,
                4,
                4,
                17,
                12,
                6,
                8,
                20,
                9,
                14,
                14,
                5,
                4,
                2,
                2,
                2,
                6,
                1,
                8,
                12,
                6,
                3,
                9,
                19,
                10,
                6,
                5,
                12,
                16,
                11,
                14,
                39,
                7,
                13,
                11,
                11,
                17,
                8,
                8,
                6,
                3,
                1,
                1,
                0,
                1,
                2,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                3,
                5,
                0,
                0,
                2,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                0,
                2,
                1,
                4,
                0,
                0,
                0,
                0,
                0,
                0,
                3,
                0,
                0,
                0,
                0,
                1,
                0,
                3,
                1,
                0
            ],
            label: 'Weekly Compromised Instances',
            type: 'simpleLineChart'
        },
        {
            change: -81.61472120100345,
            count: 1221722,
            chartData: [
                17164,
                17011,
                16675,
                20462,
                24330,
                20147,
                20271,
                19018,
                22543,
                22040,
                18259,
                26096,
                28134,
                25644,
                16336,
                9632,
                23623,
                21002,
                15270,
                16321,
                7508,
                4155,
                5650,
                6157,
                5583,
                1730,
                1724,
                8776,
                6165,
                17727,
                19819,
                22349,
                23771,
                14853,
                17487,
                23367,
                20697,
                22003,
                7869,
                3381,
                13256,
                11468,
                13290,
                12642,
                29913,
                3244,
                1837,
                1619,
                1625,
                1511,
                1751,
                1505,
                6592,
                8455,
                8735,
                8888,
                8475,
                9604,
                8618,
                10012,
                8568,
                8862,
                8663,
                2861,
                2020,
                7464,
                1823,
                1454,
                1433,
                1346,
                1466,
                1298,
                1295,
                1343,
                1365,
                6716,
                9230,
                8634,
                8964,
                9138,
                3841,
                1487,
                8945,
                13413,
                19487,
                17683,
                7309,
                3087,
                12568,
                8752,
                12757,
                12523,
                2653,
                1520,
                1939,
                1223,
                1229,
                1493,
                1307,
                1332,
                6719,
                8442,
                8423,
                6662,
                5358,
                2094,
                2443,
                5291,
                10771,
                11318,
                6294,
                2942,
                12225,
                12128,
                6641,
                3542,
                7980,
                1235,
                1162,
                1108,
                1160,
                1111,
                1061,
                1090,
                1077,
                1072,
                1105,
                1108,
                1047,
                1072,
                1088,
                1055,
                1206,
                2126,
                2824,
                1655,
                1318,
                1924,
                1087,
                1147,
                1370,
                1174,
                1080,
                1324,
                1574,
                2515,
                2105,
                1336,
                1186,
                1234,
                1203,
                1229,
                1052,
                1292,
                1496,
                1089,
                1067,
                1315,
                1095,
                2831,
                1839,
                1310,
                1066,
                1171,
                1145,
                1081,
                1425,
                0
            ],
            label: 'Weekly Events',
            type: 'simpleLineChart'
        },
        {
            chartData: {
                '': 2,
                AE: 31,
                AT: 3,
                AU: 4,
                BG: 1,
                BR: 115,
                CA: 7,
                CH: 49,
                CO: 8,
                CZ: 1,
                DE: 13,
                DK: 2,
                ES: 4,
                FR: 6,
                GB: 367,
                GR: 2,
                HK: 3,
                HN: 6,
                HR: 2,
                HU: 13,
                ID: 11,
                IE: 932,
                IL: 25,
                IN: 19,
                IT: 19,
                JP: 107,
                KR: 123,
                LT: 11,
                LV: 1,
                MX: 30,
                NG: 26,
                NO: 3,
                NZ: 18,
                PL: 67,
                PT: 6,
                QA: 8,
                RO: 71,
                RU: 27,
                RW: 1,
                SA: 120,
                SE: 42,
                SG: 5,
                SV: 2,
                TH: 14,
                TW: 110,
                US: 1930,
                UY: 1,
                ZA: 9
            },
            label: 'Instances by Location',
            type: 'pie'
        },
        {
            chartData: {
                AE: 30,
                AU: 3,
                BR: 92,
                CA: 2,
                CH: 32,
                CO: 2,
                DE: 4,
                ES: 3,
                FR: 5,
                GB: 71,
                GR: 1,
                HK: 3,
                HN: 6,
                HU: 4,
                ID: 11,
                IL: 10,
                IN: 11,
                IT: 1,
                JP: 74,
                KR: 71,
                LT: 7,
                LV: 1,
                MX: 18,
                NG: 17,
                NO: 3,
                PL: 29,
                QA: 6,
                RO: 57,
                RU: 24,
                RW: 1,
                SA: 117,
                SE: 27,
                SG: 4,
                SV: 1,
                TH: 9,
                TW: 65,
                US: 522,
                ZA: 4
            },
            label: 'Compromised Instances by Location',
            type: 'pie'
        },
        {
            count: 1374,
            chartData: {
                'Debug Detection': 139,
                'Domain Lock': 55,
                'In-App Firewall': 1251
            },
            label: 'Most Active Guards',
            type: 'bar'
        }
    ]
};
