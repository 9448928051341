/* eslint-disable no-param-reassign */
const {createSlice} = require('@reduxjs/toolkit');

const initialState = {
    loading: false
};

const appSlice = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {
        toggleLoading: (state) => {
            state.loading = !state.loading;
        }
    }
});

export default appSlice.reducer;
