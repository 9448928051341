import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getTokens = createAsyncThunk('tokens/getTokens', async (params) => fetchRequest('/users/tokens', 'GET', params));

export const generateToken = createAsyncThunk('tokens/generateToken',
    async (_, thunkAPI) => {
        await fetchRequest(`/users/tokens`, 'POST', null);
        thunkAPI.dispatch(getTokens());
    });

export const deleteUserToken = createAsyncThunk('tokens/deleteUserToken',
    async (id, thunkAPI) => {
        await fetchRequest(`/users/${id}/tokens`, 'DELETE', null);
        thunkAPI.dispatch(getTokens());
    });

export const deleteToken = createAsyncThunk('tokens/deleteToken',
    async (_, thunkAPI) => {
        await fetchRequest(`/users/tokens`, 'DELETE', null);
        thunkAPI.dispatch(getTokens());
    });
