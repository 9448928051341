/* eslint-disable indent */
import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
    @keyframes flashBackground {
        0% {
            background-color: unset;
        }
        10% {
            background-color: ${({theme}) => theme.palette.primary[50]};
        }
        20% {
            background-color: unset;
        }
        30% {
            background-color: ${({theme}) => theme.palette.primary[50]};
        }
        60% {
            background-color: ${({theme}) => theme.palette.primary[50]};
        }
        100% {
            background-color: unset;
        }
    }

    .tableContainer {
        height: 100%;
        flex: 0 1 auto;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 2rem;
    }

    .rowFlashBackground {
        animation: flashBackground 1.5s;
    }

    .loadMoreRow {
        cursor: pointer;
        position: relative;
        // This is a hack to make it work in Safari
        transform: scale(1);
        &::after {
            content: 'Load more';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            // We need to set the height so it works with the Safari approach
            height: 51px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-family: ${({theme}) => theme.typography.h4.fontFamily};
            font-size: ${({theme}) => `${theme.typography.h4.fontSize}px`};
            color: ${({theme}) => theme.palette.primary.main};
            font-style: ${({theme}) => theme.typography.h4.fontStyle};
            font-weight: ${({theme}) => theme.typography.h4.fontWeight};
            letter-spacing: ${({theme}) => `${theme.typography.h4.letterSpacing}em`};
            line-height: ${({theme}) => theme.typography.h4.lineHeight};
        }
        &.loading {
            cursor: default;
            &::after {
                content: 'Loading...';
            }
        }
    }

    .dot-snackbar-container {
        z-index: 1302;
    }

    .dialogDotMenu {
        z-index: 1301;
    }

    .smallDialog {
        &.dot-dialog {
            .MuiDialog-paper {
                max-width: 30vw;
                width: 30vw;
                max-height: 80vh;
            }
        }
    }

    .largeDialog {
        &.dot-dialog {
            .MuiDialog-paper {
                max-width: 80vw;
                width: 80vw;
                max-height: 80vh;
            }
        }
    }

    .mediumDialog {
        &.dot-dialog {
            .MuiDialog-paper {
                max-width: 50vw;
                width: 50vw;
                max-height: 80vh;
            }
        }
    }

    .controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        .left {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
            & > * {
                flex: 0 1 20rem;
            }
        }

        .right {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 1rem;
        }
    }

    .pageContent {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 1rem;
    }

    // limits the height of the autocomplete dropdown to 20% of the viewport height
    .MuiAutocomplete-paper {
        max-height: 20vh;
    }
`;

export default GlobalStyles;
