/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit';

import getUserApplications from '../api/applicationsThunk';

const initialState = {
    error: null,
    status: {
        loading: false,
        deleted: false
    },
    applications: []
};

const applicationsSlice = createSlice({
    name: 'applications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserApplications.fulfilled, (state, action) => {
                state.status.loading = false;
                state.error = null;
                state.applications = action.payload.resultData;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('applications'), (state) => {
                state.status.loading = true;
                state.error = null;
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('applications'), (state, action) => {
                state.status.loading = false;
                state.error = action.error.message;
            });
    }
});

export const getApplicationById = (applications, id) => {
    const idInt = parseInt(id, 10);
    if (!applications) {
        return null;
    }
    return applications.find((application) => application.id === idInt) || null;
};

export const getProductByParams = (applications, id, productId) => {
    const productIdInt = parseInt(productId, 10);
    const application = getApplicationById(applications, id);
    if (!application) {
        return null;
    }
    return application.products.find((product) => product.productId === productIdInt) || null;
};

export default applicationsSlice.reducer;
