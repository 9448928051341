import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getUsers = createAsyncThunk('users/getUsers', async (params) => fetchRequest('/users', 'GET', params));

export const inviteUser = createAsyncThunk('users/inviteUser',
    async (body, thunkAPI) => {
        await fetchRequest('/users', 'POST', null, body);
        thunkAPI.dispatch(getUsers());
    });

export const updateUser = createAsyncThunk('users/updateUser',
    async (body, thunkAPI) => {
        await fetchRequest(`/users/${body.id}`, 'PUT', null, body);
        thunkAPI.dispatch(getUsers());
    });

export const otpUser = createAsyncThunk('users/otpUser',
    async (body, thunkAPI) => {
        await fetchRequest(`/users/${body.id}`, 'PUT', null, body);
        thunkAPI.dispatch(getUsers());
    });

export const deleteUser = createAsyncThunk('users/deleteUser',
    async (id, thunkAPI) => {
        await fetchRequest(`/users/${id}`, 'DELETE', null);
        thunkAPI.dispatch(getUsers());
    });
