import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getDownloadAccessCode = async () => fetchRequest(`/services/lp/v1/logs/downloadAccessCode`, 'POST');

export const getAppNames = createAsyncThunk('protectionHistory/getAppNames', async (companyId) => fetchRequest('/services/usage/v1/appnames', 'POST', null, {companyId}));

export const getProtectionHistory = createAsyncThunk('protectionHistory/getAnalytics', async ({
    startDate, companyId, appName, productNames, targetPlatform
}) => fetchRequest('/services/usage/v1', 'POST', null, {
    startDate, companyId, appName, productNames, targetPlatform
}));

export const getProtectionHistoryStatus = createAsyncThunk('protectionHistory/getStatus', async ({
    companyId, appName, productNames, targetPlatform
}) => fetchRequest('/services/usage/v1/status', 'POST', null, {
    companyId, appName, productNames, targetPlatform
}));
