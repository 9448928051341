/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const LoaderDiv = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
`;
