import moment from 'moment';

const bufferToBase64 = (buffer) => Buffer.from(buffer).toString('base64');

const removeTrailingSlashes = (path) => path.replace(/^\/|\/$/, '');

/**
 * Base64 encode that support any data type and unicode characters
 * @param  {Any} str - data to be encoded
 * @return {String}  - base64 encoded data
 */
const utoa = (str) => {
    let data = str;
    if (typeof data !== 'string') {
        data = JSON.stringify(str);
    }
    return btoa(unescape(encodeURIComponent(data)));
};

/**
 * Reformat US date passed by user. It checks and fixes conditions:
 * - when year is more than 2100
 * - when months is lower than 1 or gather than 12
 * - if day for passed month is more than 31/29/28
 * - if year is passed as 2 or 3 digits
 * @param [string] data - US formatted date
 * @returns {*|moment.Moment} Moment library date
 */
const formatUSDate = (data) => {
    // remove _ from mask and prevent before passing same day without month and year ("01//") or without year ("01/01/")
    const raw = data.replace(/_/g, '').replace(/\/\//g, '').replace(/\/$/);
    const parts = raw.split('/');
    let date = moment();

    if (parts.length === 3) {
        // year
        const year = parseInt(parts[2], 10);
        parts[2] = year < 100 // eslint-disable-line
            ? `20${('0' + year).slice(-2)}` : year < 1000 // eslint-disable-line
                ? `1${year}` : year > 2100 // eslint-disable-line
                    ? new Date().getFullYear() : year; // eslint-disable-line

        // month
        const month = parseInt(parts[0], 10);
        parts[0] = month > 12 || month < 1 ? new Date().getMonth() + 1 : parts[0];
        parts[0] = (`0${parts[0]}`).slice(-2);

        // day
        const day = parseInt(parts[1], 10);
        parts[1] = day > new Date(year, month, 0).getDate() || day < 1 ? new Date().getDate() : parts[1];
        parts[1] = (`0${parts[1]}`).slice(-2);

        date = parts.join('/');
    }

    return date;
};

/**
 * Helper method for formatting large numbers
 * @param  {number} num Value, Eg: 5123
 * @return {string} Formatted number, Eg: 5.1k
 */
const shortenLargeNumber = (num) => {
    const units = ['k', 'M', 'B'];
    let decimal;
    for (let itr = units.length - 1; itr >= 0; itr -= 1) {
        decimal = 1000 ** (itr + 1);
        if (num <= -decimal || num >= decimal) {
            const numberLength = Math.floor(Math.log10(Math.abs(num)), 0);
            const diff = (Math.floor((Math.abs(num) / decimal)) > 100) ? 2 : 1;
            const roundingFactor = 10 ** (numberLength - diff);
            const rounded = (Math.sign(num) === -1)
                ? Math.ceil(num / roundingFactor) * roundingFactor
                : Math.floor(num / roundingFactor) * roundingFactor;
            return +(rounded / decimal).toPrecision(3) + units[itr];
        }
    }
    return num.toString();
};

/**
 * Helper method for calculating and formating ratios
 * @param  {number} value Value
 * @param  {number} total Total values
 * @return {string}       Formated ratio, Eg: 13%
 */
const ratio = (value, total) => {
    if (total === 0) {
        return 0;
    }
    let result;
    result = (value * 100) / total;
    result = Math.round(result);
    result = `${result}%`;
    return result;
};

export {
    bufferToBase64,
    removeTrailingSlashes,
    formatUSDate,
    utoa,
    shortenLargeNumber,
    ratio
};
