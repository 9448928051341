import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';
import omitEmpty from '../utils/omitEmpty';

export const getHistories = createAsyncThunk(
    '/apaasHistories/getHistories',
    async ({
        rowsPerPage, offset, phrase, status, companyId, orderColumn, desc
    }) => {
        const query = omitEmpty({
            rowsPerPage, offset, phrase, status, companyId, orderColumn, desc
        });
        return fetchRequest('/apps/protectionHistory', 'GET', query);
    }
);

export const getArtifact = createAsyncThunk(
    '/apaasHistories/getArtifact',
    async ({protectionId, filename}) => fetchRequest(`/apps/protectionHistory/${protectionId}/${filename}`, 'GET')
);
