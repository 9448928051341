const omitEmpty = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((prop) => {
        if ((typeof obj[prop] !== 'undefined') && (obj[prop] !== null)) {
            newObj[prop] = obj[prop];
        }
    });
    return newObj;
};

export default omitEmpty;
