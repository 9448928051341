import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';
import GlobalConst from '../constants/global';

export const getFeatures = createAsyncThunk(
    'appFeatures/getFeatures',
    async ({applicationId}) => fetchRequest('/app-features', 'GET', {applicationId})
);

export const upsertFeature = createAsyncThunk(
    'appFeatures/upsertFeatures',
    async (body) => fetchRequest('/app-features', 'POST', null, body)
);

export const toggleAppAwareFeature = createAsyncThunk(
    'appFeatures/toggleAppAwareFeature',
    async ({
        featureId,
        applicationId,
        enabled
    }) => fetchRequest('/app-features', 'POST', null, {
        featureId,
        applicationId,
        featureType: GlobalConst.APP_AWARE_FEATURE_ID,
        enabled
    })
);

export const deleteFeature = createAsyncThunk(
    'appFeatures/deleteFeatures',
    async (body) => fetchRequest('/app-features', 'DELETE', null, body)
);
