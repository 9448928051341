import lodash from 'lodash';
import Resumable from 'resumablejs';
import filesize from 'filesize';
import isTextOrBinary from 'istextorbinary';

import GlobalConsts from '../../constants/global';

const {
    ERROR_NOT_ALLOWED_EXTENSIONS,
    ERROR_FILE_TOO_SMALL,
    ERROR_FILE_TOO_BIG,
    ERROR_FILE_EXISTS,
    ERROR_FILE_IS_UPLOADING_AUTOMATICALLY,
    ERROR_MAX_FILES_EXCEEDED,
    ERROR_FILE_CONTENT_IS_NOT_A_STRING,
    ERROR_NO_INIT_DATA_FROM_SERVER
} = GlobalConsts.UPLOAD_ERRORS;

const CHUNK_STATUS_SUCCESS = 'success';

export default class Upload {
    constructor() {
        this.loading = false;
        this.times = [];
        this.files = [];
        this.inputs = [];
    }

    init(params, file) {
        if (this.resumable) return;
        this.params = params;

        const options = {
            uploadMethod: this.params.uploadMethod || 'PUT',
            maxChunkRetries: lodash.isInteger(this.params.attempts) ? this.params.attempts : 5,
            chunkRetryInterval: 10000,
            maxFiles: 1,
            maxFileSize: GlobalConsts.MAX_FILE_UPLOAD_SIZE, // 5TB old value:5368709120, max 5 GB
            maxFilesErrorCallback: this.__maxFilesErrorCallback.bind(this),
            minFileSize: GlobalConsts.MIN_FILE_UPLOAD_SIZE,
            simultaneousUploads: this.params.simultaneousUploads || 3,
            fileParameterName: 'file',
            headers: {
                Authorization: this.params.token
            },
            chunkSize: this.params.chunkSize || 1048576 * 5 // 5MB = the smallest chunk size
        };

        if (this.params.extensions && this.params.extensions.length) {
            options.fileType = this.params.extensions;
            options.fileTypeErrorCallback = this.__notAllowedFileTypeErrorCallback.bind(this);
        }

        this.resumable = new Resumable(options);

        // callbacks
        this.onError = this.params.onError;
        this.onStart = this.params.onStart;

        // EVENTS
        //* added
        this.resumable.on('fileAdded', (newFile) => {
            if (this.__checkSize(newFile.size, this.params.minBytes, this.params.maxBytes)) return;

            this.files.push(newFile);

            switch (this.params.returns) {
                case GlobalConsts.UPLOAD_PARSING_TYPES.CONTENT:
                    this.__readAndParse(newFile);
                    break;
                default:
                    if (this.params.onInit) {
                        this.params.onInit({
                            fileName: newFile.file.name,
                            total: newFile.chunks.length,
                            size: newFile.size,
                            uniqueIdentifier: newFile.uniqueIdentifier,
                            type: newFile.file.type
                        });
                    }
            }
        });

        //* progress
        this.resumable.on('fileProgress', (newFile) => {
            const uploadId = this.params.firstChunkInitialize && !this.resumable.opts.query.uploadId && this.__getInitIdFromChunk(newFile);

            if (uploadId) {
                this.resumable.opts = {...this.resumable.opts, query: {uploadId}};
            }

            if (this.params.returns === GlobalConsts.UPLOAD_PARSING_TYPES.CONTENT) return;

            const now = new Date().getTime();
            const progress = newFile.progress() || 0;
            const remaining = 1 - progress;

            if (progress < this.beingUploaded) {
                this.lastTime = now;
                this.startTime = now;
            }

            const timeDelta = now - this.startTime;
            const checkingDelta = now - (this.lastTime || this.startTime);

            if (checkingDelta > GlobalConsts.UPLOAD_ESTIMATED_TIME_CHECK_INTERVAL) {
                const chunkTime = ((remaining / (progress - this.beingUploaded)) * timeDelta) / 1000;

                this.lastTime = now;
                this.times.splice(GlobalConsts.UPLOAD_ESTIMATED_TIME_GRANULARITY - 1, 1);
                this.times.unshift(chunkTime);

                if (this.times.length > (GlobalConsts.UPLOAD_ESTIMATED_TIME_MIN_GRANULARITY - 1)) {
                    const time = Math.round(lodash.sum(this.times) / this.times.length);

                    if (Number.isFinite(time) && Math.round(time) > 0) {
                        this.remaining = time;
                    }
                }
            }

            if (this.params.onProgress) {
                this.params.onProgress = {
                    id: this.currentFile.id,
                    progress,
                    remaining: this.remaining,
                    filesize: this.currentFile.filesize
                };
            }
        });

        //* success
        this.resumable.on('fileSuccess', (fileToResume, response) => {
            this.resumable.cancel();
            if (this.params.onComplete) this.params.onComplete(response);
        });

        //* error
        this.resumable.on('error', (message, thisfile) => {
            if (!this.params.retryAbility) this.resumable.cancel();
            if (this.onError) this.onError('generalError', {thisfile});
        });

        //* cancel
        this.resumable.on('cancel', () => {
            this.loading = false;
            this.remaining = null;
            this.resumable.opts.target = null;
        });

        this.addFile(file);
    }

    addFile(file) {
        if (file) this.resumable.addFile(file);
    }

    run(fileInfo) {
        if (!lodash.has(fileInfo, 'id') || !lodash.has(fileInfo, 'status') || !lodash.has(fileInfo, 'progress') || !lodash.has(fileInfo, 'name')
          || !lodash.has(fileInfo, 'total') || !lodash.has(fileInfo, 'hash') || !lodash.has(fileInfo, 'total') || !this.files.length) return;

        const file = this.files.pop();

        this.startTime = new Date().getTime();
        this.currentFile = {...fileInfo, filesize: file.size};
        this.beingUploaded = lodash.floor((this.currentFile.progress / this.currentFile.total), 2);
        if (this.onStart) {
            this.onStart(this.currentFile);
        }

        if (lodash.isInteger(this.currentFile.id) && lodash.isEmpty(this.currentFile.url) && (this.currentFile.status === 'started' || this.currentFile.status === 'in progress')) {
            file.uniqueIdentifier = this.currentFile.hash;

            this.resumable.opts.target = this.currentFile.target;
            this.resumable.opts.query = {resumableCategory: this.currentFile.type || '', fileName: fileInfo.name};

            this.resumable.upload();
            this.loading = true;
        } else {
            this.__fileExists(fileInfo);
        }
    }

    abort(uniqueIdentifier) {
        const len = this.resumable.files.length;
        const file = lodash.find(this.resumable.files, {uniqueIdentifier});

        // cancel whole resumable and turn back triggers to ready state
        if (len === 1 && file) this.cancel();

        // cancel single file if exists
        if (file) file.cancel();

        if (this.params.onAbort) this.params.onAbort();
    }

    cancel() {
        this.resumable.cancel();
    }

    pause() {
        if (this.resumable.isUploading()) {
            this.resumable.pause();
            if (this.params.onPause) this.params.onPause();
        }
    }

    resume() {
        if (!this.resumable.isUploading()) {
            this.resumable.upload();
            if (this.params.onResume) this.params.onResume();
        }
    }

    isLoading() {
        return this.loading;
    }

    assignTriggers(browses, drop) {
        if (browses.length && browses[0]) {
            browses.forEach((browse) => {
                this.assignBrowse(browse.wrapper || browse);
            });
        }

        if (drop) {
            this.resumable.assignDrop(drop);
            this.__preventDrop(drop, true);
        }
    }

    unassignTriggers(browses, drop) {
        this.__preventDrop(drop);
        this.resumable.unAssignDrop(drop);
        browses.forEach((browse) => {
            this.unassignBrowse(browse.wrapper || browse);
        });
    }

    assignBrowse(domNode, callback) {
        if (domNode.querySelector('input')) {
            return;
        }

        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.style.display = 'none';
        input.addEventListener('change', callback || ((event) => {
            this.addFile(event.target.files[0]);
            event.target.value = ''; // eslint-disable-line no-param-reassign
        }), false);

        const onClick = () => {
            Object.assign(input.style, {
                opacity: 0,
                display: 'block'
            });

            input.focus();
            input.click();
            input.style.display = 'none';
        };

        domNode.addEventListener('click', onClick, false);
        domNode.appendChild(input);

        domNode.unassign = () => { // eslint-disable-line no-param-reassign
            domNode.removeEventListener('click', onClick);
            if (input.parentNode) {
                domNode.removeChild(input);
            }
            delete domNode.unassign; // eslint-disable-line no-param-reassign
        };
    }

    unassignBrowse(domNode) {
        if (domNode && domNode.unassign) {
            domNode.unassign();
        }
    }

    // private parts
    __readAndParse(file) {
        const reader = new FileReader();

        reader.onload = () => {
            this.cancel();

            if (!isTextOrBinary.isTextSync(file.fileName, reader.result)) {
                if (this.onError) {
                    this.onError(ERROR_FILE_CONTENT_IS_NOT_A_STRING, file);
                }
                return;
            }

            if (this.params.onComplete) {
                this.params.onComplete({
                    fileName: file.fileName,
                    size: file.size,
                    content: reader.result
                });
            }
        };
        reader.readAsText(file.file);
    }

    __checkSize(size, minBytes, maxBytes) {
        let isError = false;

        if ((minBytes && size < minBytes) || size === 0) {
            isError = true;
            if (this.onError) {
                this.onError(ERROR_FILE_TOO_SMALL, {allowedSize: filesize(minBytes || 1)});
            }
        } else if (maxBytes && size > maxBytes) {
            isError = true;
            if (this.onError) {
                this.onError(ERROR_FILE_TOO_BIG, {allowedSize: filesize(maxBytes)});
            }
        }

        if (isError) {
            this.resumable.cancel();
        }

        return isError;
    }

    __notAllowedFileTypeErrorCallback() {
        this.resumable.cancel();
        if (this.onError) {
            this.onError(ERROR_NOT_ALLOWED_EXTENSIONS, {extensions: this.params.extensions});
        }
    }

    __fileExists(file) {
        this.resumable.removeFile(file);
        if (this.onError) {
            this.onError(lodash.isEmpty(file.url) ? ERROR_FILE_EXISTS : ERROR_FILE_IS_UPLOADING_AUTOMATICALLY, {file});
        }
    }

    __maxFilesErrorCallback() {
        if (this.onError) this.onError(ERROR_MAX_FILES_EXCEEDED, this.resumable.opts.maxFiles);
    }

    __preventDrop(drops, unassign = false) {
        lodash.map(drops, (drop) => {
            if (unassign) {
                drop.removeEventListener('dragenter', this.__preventDefault);
                drop.removeEventListener('dragover', this.__preventDefault);
                drop.removeEventListener('drop', this.__preventDefault);
            } else {
                drop.addEventListener('dragenter', this.__preventDefault, false);
                drop.addEventListener('dragover', this.__preventDefault, false);
                drop.addEventListener('drop', this.__preventDefault, false);
            }
        });
    }

    __preventDefault(sourceEvent) {
        // eslint-disable-next-line
        const eventClone = sourceEvent || event;
        eventClone.preventDefault();
    }

    __getInitIdFromChunk = (file) => {
        let json = {};

        try {
            json = JSON.parse(lodash.head(file.chunks).message());
        } catch (err) {
            // testing if error is present on second chunk because first chunk should raise an error
            if (lodash.head(file.chunks) && lodash.head(file.chunks).status() === CHUNK_STATUS_SUCCESS && this.onError) {
                this.onError(ERROR_NO_INIT_DATA_FROM_SERVER, {file: this.currentFile});
            }
        }

        return json.uploadId;
    }
}
