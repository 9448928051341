import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';
import omitEmpty from '../utils/omitEmpty';
import {getKitUploads} from './kitsThunk';
import {getReleaseUploads} from './releaseThunk';

export const getReleases = createAsyncThunk('releases/getReleases', async (params) => fetchRequest('/releases', 'GET', params));

export const initReleaseUpload = createAsyncThunk('release/initReleaseUpload', async ({
    releaseId, name, total, size, type
}) => {
    const query = omitEmpty({
        fileName: name,
        total,
        size,
        type
    });
    return fetchRequest(`/releases/${releaseId}/uploads`, 'POST', {releaseId}, query);
});

export const initKitUpload = createAsyncThunk('kits/upload', async ({
    kitId, name, total, size, type
}) => {
    const query = omitEmpty({
        fileName: name,
        total,
        size,
        type
    });
    return fetchRequest(`/kits/${kitId}/uploads`, 'POST', {kitId}, query);
});

export const initShareUpload = createAsyncThunk('share/upload', async ({
    companyId, name, total, size, type
}) => {
    const query = omitEmpty({
        fileName: name,
        total,
        size,
        type
    });
    return fetchRequest(`/share/${companyId}/uploads`, 'POST', {companyId}, query);
});

export const upload = createAsyncThunk(
    'releases/upload',
    async (body, thunkAPI) => {
        const {id, ...data} = body;
        await fetchRequest(`/releases/${id}/uploads/uploadId`, 'PUT', null, data);
        thunkAPI.dispatch(getReleases());
    }
);

export const updateReleaseFileType = createAsyncThunk(
    'release/updateReleaseFileType',
    async (body, thunkAPI) => {
        const {releaseId, ...data} = body;
        await fetchRequest(`/releases/${releaseId}/uploads/${data.uploadId}/assign/${data.type}`, 'PUT', null, data);
        thunkAPI.dispatch(getReleaseUploads(releaseId));
    }
);

export const updateKitFileType = createAsyncThunk(
    'kits/updateKitFileType',
    async ({kitId, ...data}, thunkAPI) => {
        await fetchRequest(`/kits/${kitId}/uploads/${data.uploadId}/assign/${data.type}`, 'PUT', null, data);
        thunkAPI.dispatch(getKitUploads(kitId));
    }
);
