/* eslint-disable no-param-reassign */
import {merge} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';

import {
    getApplications,
    updateApplication,
    deleteApplication,
    getApplicationProperties,
    getAllApplicationProperties,
    upsertApplicationProperties
} from '../api/appManageThunk';

const setFulfilledState = (state) => {
    const status = {
        loading: false,
        updated: false,
        deleted: false,
        propertiesUpserted: false
    };
    state.status = merge(state.status, status);
    state.error = null;
};

const setPendingState = (state) => {
    const status = {
        loading: true,
        updated: false,
        deleted: false,
        propertiesUpserted: false
    };
    state.status = merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false,
        updated: false,
        deleted: false,
        propertiesUpserted: false
    };
    state.status = merge(state.status, status);
    state.error = action.error.message;
};

const initialState = {
    error: null,
    status: {
        loading: false,
        updated: false,
        deleted: false,
        propertiesUpserted: false
    },
    applications: [],
    application: null,
    appAwareOperatingSystems: {},
    applicationProperties: null,
    allApplicationProperties: null
};

const appManageSlice = createSlice({
    name: 'appManage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getApplications.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.applications = action.payload.rows;
                state.appAwareOperatingSystems = action.payload.taOperatingSystems;
            })
            .addCase(updateApplication.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.status.updated = true;
                state.application = action.payload;
            })
            .addCase(deleteApplication.fulfilled, (state) => {
                setFulfilledState(state);
                state.status.deleted = true;
            })
            .addCase(getApplicationProperties.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.applicationProperties = action.payload;
            })
            .addCase(getAllApplicationProperties.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.allApplicationProperties = action.payload;
            })
            .addCase(upsertApplicationProperties.fulfilled, (state) => {
                setFulfilledState(state);
                state.status.propertiesUpserted = true;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('appManage'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('appManage'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export default appManageSlice.reducer;
