/* eslint-disable no-param-reassign */
import {merge} from 'lodash';
import {createSlice, createSelector} from '@reduxjs/toolkit';

import {
    getMessages, sendMessage, deleteMessage, createMessage, updateMessage
} from '../api/messagesThunk';

const setPendingState = (state) => {
    const status = {
        loading: true,
        deleted: false,
        created: false,
        updated: false,
        sent: false
    };
    state.status = merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false,
        deleted: false,
        created: false,
        updated: false,
        sent: false
    };
    state.status = merge(state.status, status);
    state.error = true;
    state.errorMessage = action.error.message;
};

const setFulfilledState = (state) => {
    const status = {
        loading: false,
        deleted: false,
        created: false,
        updated: false,
        sent: false
    };
    state.status = merge(state.status, status);
    state.error = false;
    state.errorMessage = null;
};

const initialState = {
    error: null,
    errorMessage: null,
    status: {
        loading: false,
        deleted: false,
        created: false,
        updated: false,
        sent: false
    },
    created: null,
    data: []
};

const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMessages.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.data = action.payload;
            })
            .addCase(sendMessage.fulfilled, (state) => {
                setFulfilledState(state);
                state.status.sent = true;
            })
            .addCase(updateMessage.fulfilled, (state) => {
                setFulfilledState(state);
                state.created = null;
                state.status.updated = true;
            })
            .addCase(deleteMessage.fulfilled, (state) => {
                setFulfilledState(state);
                state.created = null;
                state.status.deleted = true;
            })
            .addCase(createMessage.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.created = action.payload;
                state.status.created = true;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('messages'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('messages'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export const getDrafts = createSelector((state) => state.messagesReducer.data, (data) => data.filter((item) => !item.isPublished));
export const getSent = createSelector((state) => state.messagesReducer.data, (data) => data.filter((item) => item.isPublished));
export const getMessageById = createSelector([(state) => state.messagesReducer.data, (data, messageId) => messageId], (data, messageId) => data?.find((item) => messageId === item.id));
export default messagesSlice.reducer;
