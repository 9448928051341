import keyMirror from 'fbjs/lib/keyMirror';

// keyMirror is used to fant
export default keyMirror({

    // authentication related action types

    // login
    AUTH_LOGIN_START: 1,
    AUTH_LOGIN_SUCCESS: 1,
    AUTH_LOGIN_FAIL: 1,

    // sso login config_url configuration
    AUTH_SSO_LOGIN_GET_CONFIGURATION: 1,

    // sso login
    AUTH_SSO_LOGIN_START: 1,
    AUTH_SSO_LOGIN_SUCCESS: 1,
    AUTH_SSO_LOGIN_FAIL: 1,

    // keycloak
    AUTH_SSO_RETURN_KC: 1,

    // sso login config
    AUTH_SSO_LOGIN_GET_CONFIG_FROM_K6I_START: 1,
    AUTH_SSO_LOGIN_GET_CONFIG_FROM_K6I_SUCCESS: 1,
    AUTH_SSO_LOGIN_GET_CONFIG_FROM_K6I_FAIL: 1,

    // otp
    AUTH_OTP_START: 1,
    AUTH_OTP_SUCCESS: 1,
    AUTH_OTP_FAIL: 1,

    // auth validation
    AUTH_VALIDATION_START: 1,
    AUTH_VALIDATION_SUCCESS: 1,
    AUTH_VALIDATION_FAIL: 1,

    // logout
    AUTH_LOGOUT_START: 1,
    AUTH_LOGOUT_SUCCESS: 1,
    AUTH_LOGOUT_FAIL: 1,

    AUTH_SESSION_EXPIRED: 1,

    AUTH_CLEAR_DATA: 1,
    AUTH_TOKEN_UPDATE: 1,

    // password reset
    PASSWORD_RESET_START: 1,
    PASSWORD_RESET_SUCCESS: 1,
    PASSWORD_RESET_FAIL: 1,

    // password setup
    ACCOUNT_SETUP_START: 1,
    ACCOUNT_SETUP_SUCCESS: 1,
    ACCOUNT_SETUP_FAIL: 1,

    // profile page
    PROFILE_SAVE_START: 1,
    PROFILE_SAVE_SUCCESS: 1,
    PROFILE_SAVE_FAIL: 1,
    PROFILE_SAVE_DELIVERY_SETTINGS_START: 1,
    PROFILE_SAVE_DELIVERY_SETTINGS_SUCCESS: 1,
    PROFILE_SAVE_DELIVERY_SETTINGS_FAIL: 1,
    PROFILE_CLOSE_BANNER: 1,

    // form validation
    VALIDATE: 1,
    VALIDATE_EXCLUSION: 1,

    // restricted countries
    ACOUNTRIES_GET_START: 1,
    ACOUNTRIES_GET_SUCCESS: 1,
    ACOUNTRIES_GET_FAIL: 1,

    ACOUNTRIES_POST_START: 1,
    ACOUNTRIES_POST_SUCCESS: 1,
    ACOUNTRIES_POST_FAIL: 1,

    ACOUNTRIES_DELETE_START: 1,
    ACOUNTRIES_DELETE_SUCCESS: 1,
    ACOUNTRIES_DELETE_FAIL: 1,

    // products related actions

    TOOLS_GET_START: 1,
    TOOLS_GET_SUCCESS: 1,
    TOOLS_GET_FAIL: 1,

    ALL_PRODUCTS_GET_START: 1,
    ALL_PRODUCTS_GET_SUCCESS: 1,
    ALL_PRODUCTS_GET_FAIL: 1,

    ALL_RELEASES_GET_START: 1,
    ALL_RELEASES_GET_SUCCESS: 1,
    ALL_RELEASES_GET_FAIL: 1,

    RELEASE_GET_START: 1,
    RELEASE_GET_SUCCESS: 1,
    RELEASE_GET_FAIL: 1,

    RELEASE_DELETE_START: 1,
    RELEASE_DELETE_SUCCESS: 1,
    RELEASE_DELETE_FAIL: 1,

    RELEASE_SAVE_START: 1,
    RELEASE_SAVE_SUCCESS: 1,
    RELEASE_SAVE_FAIL: 1,

    RELEASE_UPLOADS_GET_START: 1,
    RELEASE_UPLOADS_GET_SUCCESS: 1,
    RELEASE_UPLOADS_GET_FAIL: 1,

    RELEASE_EULA_ACCEPTED: 1,

    KIT_UPLOADS_GET_START: 1,
    KIT_UPLOADS_GET_SUCCESS: 1,
    KIT_UPLOADS_GET_FAIL: 1,

    RELEASE_UPLOADS_DELETE_START: 1,
    RELEASE_UPLOADS_DELETE_SUCCESS: 1,
    RELEASE_UPLOADS_DELETE_FAIL: 1,

    KIT_UPLOADS_DELETE_START: 1,
    KIT_UPLOADS_DELETE_SUCCESS: 1,
    KIT_UPLOADS_DELETE_FAIL: 1,

    // get user releases
    USER_RELEASES_GET_START: 1,
    USER_RELEASES_GET_SUCCESS: 1,
    USER_RELEASES_GET_FAIL: 1,

    // users related action types
    USERS_LOAD_START: 1,
    USERS_LOAD_SUCCESS: 1,
    USERS_LOAD_FAIL: 1,

    USER_UPDATE_START: 1,
    USER_UPDATE_SUCCESS: 1,
    USER_UPDATE_FAIL: 1,

    USER_DELETE_START: 1,
    USER_DELETE_SUCCESS: 1,
    USER_DELETE_FAIL: 1,

    UPLOAD_RELEASE_FILE_INIT_START: 1,
    UPLOAD_RELEASE_FILE_INIT_SUCCESS: 1,
    UPLOAD_RELEASE_FILE_INIT_FAIL: 1,

    UPLOAD_RELEASE_FILE_START: 1,
    UPLOAD_RELEASE_FILE_SUCCESS: 1,
    UPLOAD_RELEASE_FILE_FAIL: 1,

    UPDATE_RELEASE_FILE_START: 1,
    UPDATE_RELEASE_FILE_SUCCESS: 1,
    UPDATE_RELEASE_FILE_FAIL: 1,

    UPDATE_FILE_TYPE_START: 1,
    UPDATE_FILE_TYPE_SUCCESS: 1,
    UPDATE_FILE_TYPE_FAIL: 1,

    UPDATE_FILE_TYPE_CLEAR: 1,

    UPDATE_FILE_INIT_CLEAR: 1,

    USER_INVITE_START: 1,
    USER_INVITE_SUCCESS: 1,
    USER_INVITE_FAIL: 1,

    DOWNLOAD_RELEASE_START: 1,

    UPLOAD_NOTIFICATION_SUCCESS: 1,
    UPLOAD_NOTIFICATION_FAIL: 1,

    ADD_NOTIFICATION: 1,
    CLEAR_NOTIFICATIONS: 1,

    // licenses
    LICENSE_GET_START: 1,
    LICENSE_GET_SUCCESS: 1,
    LICENSE_GET_FAIL: 1,

    LICENSE_REGENERATE_START: 1,
    LICENSE_REGENERATE_SUCCESS: 1,
    LICENSE_REGENERATE_FAIL: 1,

    LICENSE_FINGERPRINT_VALIDATION_SUCCESS: 1,
    LICENSE_FINGERPRINT_VALIDATION_FAIL: 1,

    ALL_LICENSES_GET_START: 1,
    ALL_LICENSES_GET_SUCCESS: 1,
    ALL_LICENSES_GET_FAIL: 1,

    LICENSE_POST_START: 1,
    LICENSE_POST_SUCCESS: 1,
    LICENSE_POST_FAIL: 1,

    LICENSE_DELETE_START: 1,
    LICENSE_DELETE_SUCCESS: 1,
    LICENSE_DELETE_FAIL: 1,

    DOWNLOAD_LICENSE_START: 1,
    DOWNLOAD_LICENSE_SUCCESS: 1,
    DOWNLOAD_LICENSE_FAIL: 1,

    // kits
    KIT_GET_START: 1,
    KIT_GET_SUCCESS: 1,
    KIT_GET_FAIL: 1,

    KIT_GET_ALL_PLATFORMS_START: 1,
    KIT_GET_ALL_PLATFORMS_SUCCESS: 1,
    KIT_GET_ALL_PLATFORMS_FAIL: 1,

    ALL_KITS_GET_START: 1,
    ALL_KITS_GET_SUCCESS: 1,
    ALL_KITS_GET_FAIL: 1,

    KIT_POST_START: 1,
    KIT_POST_SUCCESS: 1,
    KIT_POST_FAIL: 1,

    KIT_GET_CIPHERS_START: 1,
    KIT_GET_CIPHERS_SUCCESS: 1,
    KIT_GET_CIPHERS_FAIL: 1,

    KIT_GET_PLATFORMS_START: 1,
    KIT_GET_PLATFORMS_SUCCESS: 1,
    KIT_GET_PLATFORMS_FAIL: 1,

    KIT_GET_CONTRACTS_START: 1,
    KIT_GET_CONTRACTS_SUCCESS: 1,
    KIT_GET_CONTRACTS_FAIL: 1,

    KIT_UPDATE_STATUS_START: 1,
    KIT_UPDATE_STATUS_SUCCESS: 1,
    KIT_UPDATE_STATUS_FAIL: 1,

    KIT_CHECK_START: 1,
    KIT_CHECK_SUCCESS: 1,
    KIT_CHECK_FAIL: 1,

    DOWNLOAD_KIT_FILE_START: 1,

    DOWNLOAD_KIT_START: 1,

    COMPANIES_LOAD_START: 1,
    COMPANIES_LOAD_SUCCESS: 1,
    COMPANIES_LOAD_FAIL: 1,

    COMPANIES_ENTITLED_LOAD_START: 1,
    COMPANIES_ENTITLED_LOAD_SUCCESS: 1,
    COMPANIES_ENTITLED_LOAD_FAIL: 1,

    COMPANY_UPDATE_START: 1,
    COMPANY_UPDATE_SUCCESS: 1,
    COMPANY_UPDATE_FAIL: 1,

    COMPANY_DELETE_START: 1,
    COMPANY_DELETE_SUCCESS: 1,
    COMPANY_DELETE_FAIL: 1,

    CONTRACTS_LOAD_START: 1,
    CONTRACTS_LOAD_SUCCESS: 1,
    CONTRACTS_LOAD_FAIL: 1,

    CONTRACT_UPDATE_START: 1,
    CONTRACT_UPDATE_SUCCESS: 1,
    CONTRACT_UPDATE_FAIL: 1,

    SHARE_DOWNLOADS_GET_START: 1,
    SHARE_DOWNLOADS_GET_SUCCESS: 1,
    SHARE_DOWNLOADS_GET_FAIL: 1,

    SHARE_DOWNLOADS_DELETE_START: 1,
    SHARE_DOWNLOADS_DELETE_SUCCESS: 1,
    SHARE_DOWNLOADS_DELETE_FAIL: 1,

    DOWNLOAD_SHARE_START: 1,
    DOWNLOAD_SHARE_SUCCESS: 1,
    DOWNLOAD_SHARE_FAIL: 1,

    APAAS_FETCH_ALL_START: 1,
    APAAS_FETCH_ALL_SUCCESS: 1,
    APAAS_FETCH_ALL_FAIL: 1,

    APAAS_FETCH_START: 1,
    APAAS_FETCH_SUCCESS: 1,
    APAAS_FETCH_FAIL: 1,

    APAAS_PROTECT_START: 1,
    APAAS_PROTECT_SUCCESS: 1,
    APAAS_PROTECT_FAIL: 1,

    APAAS_STOP_START: 1,
    APAAS_STOP_SUCCESS: 1,
    APAAS_STOP_FAIL: 1,

    APAAS_DELETE_START: 1,
    APAAS_DELETE_SUCCESS: 1,
    APAAS_DELETE_FAIL: 1,

    APAAS_UPDATE_START: 1,
    APAAS_UPDATE_SUCCESS: 1,
    APAAS_UPDATE_FAIL: 1,

    APAAS_FETCH_USER_APPS_START: 1,
    APAAS_FETCH_USER_APPS_FAIL: 1,
    APAAS_FETCH_USER_APPS_SUCCESS: 1,

    APAAS_FETCH_HISTORY_START: 1,
    APAAS_FETCH_HISTORY_SUCCESS: 1,
    APAAS_FETCH_HISTORY_FAIL: 1,

    APAAS_SET_APP_START: 1,
    APAAS_SET_APP_SUCCESS: 1,

    APAAS_REMOVE_SHELL_START: 1,
    APAAS_REMOVE_SHELL_SUCCESS: 1,

    APAAS_HISTORY_FETCH_START: 1,
    APAAS_HISTORY_FETCH_SUCCESS: 1,
    APAAS_HISTORY_FETCH_FAIL: 1,

    APAAS_HISTORY_FETCH_ARTIFACT_START: 1,
    APAAS_HISTORY_FETCH_ARTIFACT_SUCCESS: 1,
    APAAS_HISTORY_FETCH_ARTIFACT_FAIL: 1,

    UPLOAD_MANAGER_SUBSCRIBE_START: 1,
    UPLOAD_MANAGER_SUBSCRIBE_SUCCESS: 1,

    UPLOAD_MANAGER_UNSUBSCRIBE_START: 1,
    UPLOAD_MANAGER_UNSUBSCRIBE_SUCCESS: 1,

    UPLOAD_MANAGER_STATUS_CHANGE_START: 1,
    UPLOAD_MANAGER_STATUS_CHANGE_SUCCESS: 1,

    // OAuth
    OAUTH_GET_ALL_START: 1,
    OAUTH_GET_ALL_SUCCESS: 1,
    OAUTH_GET_ALL_FAIL: 1,

    OAUTH_GET_START: 1,
    OAUTH_GET_SUCCESS: 1,
    OAUTH_GET_FAIL: 1,

    OAUTH_PUT_START: 1,
    OAUTH_PUT_SUCCESS: 1,
    OAUTH_PUT_FAIL: 1,

    OAUTH_POST_START: 1,
    OAUTH_POST_SUCCESS: 1,
    OAUTH_POST_FAIL: 1,

    OAUTH_DELETE_START: 1,
    OAUTH_DELETE_SUCCESS: 1,
    OAUTH_DELETE_FAIL: 1,

    // Tokens
    TOKEN_FETCH_ALL_START: 1,
    TOKEN_FETCH_ALL_SUCCESS: 1,
    TOKEN_FETCH_ALL_FAIL: 1,

    TOKEN_GENERATE_START: 1,
    TOKEN_GENERATE_SUCCESS: 1,
    TOKEN_GENERATE_FAIL: 1,

    TOKEN_DELETE_TOKEN_START: 1,
    TOKEN_DELETE_TOKEN_SUCCESS: 1,
    TOKEN_DELETE_TOKEN_FAIL: 1,

    TOKEN_DELETE_USER_TOKEN_START: 1,
    TOKEN_DELETE_USER_TOKEN_SUCCESS: 1,
    TOKEN_DELETE_USER_TOKEN_FAIL: 1,

    // Reports
    DOWNLOAD_REPORT_START: 1,
    DOWNLOAD_REPORT_SUCCESS: 1,
    DOWNLOAD_REPORT_FAIL: 1,

    // Analytics
    ANALYTICS_GET_START: 1,
    ANALYTICS_GET_SUCCESS: 1,
    ANALYTICS_GET_FAIL: 1,
    ANALYTICS_GET_STATUS_START: 1,
    ANALYTICS_GET_STATUS_SUCCESS: 1,
    ANALYTICS_GET_STATUS_FAIL: 1,
    ANALYTICS_GET_NAMES_START: 1,
    ANALYTICS_GET_NAMES_SUCCESS: 1,
    ANALYTICS_GET_NAMES_FAIL: 1,

    // Messaging system
    MESSAGE_POST_START: 1,
    MESSAGE_POST_SUCCESS: 1,
    MESSAGE_POST_FAIL: 1,
    MESSAGE_EDIT_START: 1,
    MESSAGE_EDIT_SUCCESS: 1,
    MESSAGE_EDIT_FAIL: 1,
    MESSAGE_DELETE_START: 1,
    MESSAGE_DELETE_SUCCESS: 1,
    MESSAGE_DELETE_FAIL: 1,
    MESSAGE_SEND_START: 1,
    MESSAGE_SEND_SUCCESS: 1,
    MESSAGE_SEND_FAIL: 1,
    MESSAGE_GET_ALL_START: 1,
    MESSAGE_GET_ALL_SUCCESS: 1,
    MESSAGE_GET_ALL_FAIL: 1,
    MESSAGE_GET_START: 1,
    MESSAGE_GET_SUCCESS: 1,
    MESSAGE_GET_FAIL: 1,

    USERMESSAGE_GET_ALL_START: 1,
    USERMESSAGE_GET_ALL_SUCCESS: 1,
    USERMESSAGE_GET_ALL_FAIL: 1,
    USERMESSAGE_GET_START: 1,
    USERMESSAGE_GET_SUCCESS: 1,
    USERMESSAGE_GET_FAIL: 1,
    USERMESSAGE_DELETE_START: 1,
    USERMESSAGE_DELETE_SUCCESS: 1,
    USERMESSAGE_DELETE_FAIL: 1,

    // Threat analytics
    THREAT_CANCEL_REQUESTS: 1,

    THREAT_GET_TOKENS_START: 1,
    THREAT_GET_TOKENS_SUCCESS: 1,
    THREAT_GET_TOKENS_FAIL: 1,
    THREAT_UPSERT_TOKEN_START: 1,
    THREAT_UPSERT_TOKEN_SUCCESS: 1,
    THREAT_UPSERT_TOKEN_FAIL: 1,

    THREAT_GET_OVERVIEW_START: 1,
    THREAT_GET_OVERVIEW_SUCCESS: 1,
    THREAT_GET_OVERVIEW_FAIL: 1,

    THREAT_GENERATE_REPORT_START: 1,
    THREAT_GENERATE_REPORT_SUCCESS: 1,
    THREAT_GENERATE_REPORT_FAIL: 1,

    THREAT_DOWNLOAD_REPORT: 1,

    THREAT_FETCH_TABLE_START: 1,
    THREAT_FETCH_TABLE_SUCCESS: 1,
    THREAT_FETCH_TABLE_FAIL: 1,

    THREAT_GET_EVENTS_START: 1,
    THREAT_GET_EVENTS_SUCCESS: 1,
    THREAT_GET_EVENTS_FAIL: 1,

    THREAT_GET_ENDPOINTS_START: 1,
    THREAT_GET_ENDPOINTS_SUCCESS: 1,
    THREAT_GET_ENDPOINTS_FAIL: 1,

    THREAT_REQUEST_ENDPOINT_START: 1,
    THREAT_REQUEST_ENDPOINT_SUCCESS: 1,
    THREAT_REQUEST_ENDPOINT_FAIL: 1,

    THREAT_GET_UAI_START: 1,
    THREAT_GET_UAI_SUCCESS: 1,
    THREAT_GET_UAI_FAIL: 1,

    THREAT_GET_GUARD_ACTIVITY_TOTAL_START: 1,
    THREAT_GET_GUARD_ACTIVITY_TOTAL_SUCCESS: 1,
    THREAT_GET_GUARD_ACTIVITY_TOTAL_FAIL: 1,

    THREAT_GET_GUARDS_FIRED_START: 1,
    THREAT_GET_GUARDS_FIRED_SUCCESS: 1,
    THREAT_GET_GUARDS_FIRED_FAIL: 1,

    THREAT_FETCH_ALERTS_START: 1,
    THREAT_FETCH_ALERTS_SUCCESS: 1,
    THREAT_FETCH_ALERTS_FAIL: 1,

    THREAT_GET_DEFINITIONS_START: 1,
    THREAT_GET_DEFINITIONS_SUCCESS: 1,
    THREAT_GET_DEFINITIONS_FAIL: 1,

    // S3 File list
    S3_LIST_FILES_START: 1,
    S3_LIST_FILES_SUCCESS: 1,
    S3_LIST_FILES_FAIL: 1,

    // App Feature Management
    APPLICATION_FETCH_ALL_START: 1,
    APPLICATION_FETCH_ALL_SUCCESS: 1,
    APPLICATION_FETCH_ALL_FAIL: 1,

    APPLICATION_UPSERT_START: 1,
    APPLICATION_UPSERT_SUCCESS: 1,
    APPLICATION_UPSERT_FAIL: 1,

    APPLICATION_DELETE_START: 1,
    APPLICATION_DELETE_SUCCESS: 1,
    APPLICATION_DELETE_FAIL: 1,

    APP_FEATURE_FETCH_ALL_START: 1,
    APP_FEATURE_FETCH_ALL_SUCCESS: 1,
    APP_FEATURE_FETCH_ALL_FAIL: 1,

    APP_FEATURE_UPSERT_START: 1,
    APP_FEATURE_UPSERT_SUCCESS: 1,
    APP_FEATURE_UPSERT_FAIL: 1,

    APP_FEATURE_DELETE_START: 1,
    APP_FEATURE_DELETE_SUCCESS: 1,
    APP_FEATURE_DELETE_FAIL: 1,

    // Response rules
    RULES_FETCH_ALL_START: 1,
    RULES_FETCH_ALL_SUCCESS: 1,
    RULES_FETCH_ALL_FAIL: 1,

    RULES_FETCH_START: 1,
    RULES_FETCH_SUCCESS: 1,
    RULES_FETCH_FAIL: 1,

    RULES_UPSERT_START: 1,
    RULES_UPSERT_SUCCESS: 1,
    RULES_UPSERT_FAIL: 1,

    RULES_DELETE_START: 1,
    RULES_DELETE_SUCCESS: 1,
    RULES_DELETE_FAIL: 1,

    // THREAT LOG VISUALIZATION
    THREAT_FETCH_THREAT_LOG_START: 1,
    THREAT_FETCH_THREAT_LOG_SUCCESS: 1,
    THREAT_FETCH_THREAT_LOG_FAIL: 1,

    THREAT_UPDATE_THREAT_LOG_ITEM_START: 1,
    THREAT_UPDATE_THREAT_LOG_ITEM_SUCCESS: 1,
    THREAT_UPDATE_THREAT_LOG_ITEM_FAIL: 1,

    THREAT_LOG_FETCH_DETAILS_START: 1,
    THREAT_LOG_FETCH_DETAILS_SUCCESS: 1,
    THREAT_LOG_FETCH_DETAILS_FAIL: 1,

    // Notification rules
    NOTIFICATION_RULE_FETCH_START: 1,
    NOTIFICATION_RULE_CREATE_START: 1,
    NOTIFICATION_RULE_UPDATE_START: 1,

    NOTIFICATION_RULE_FETCH_SUCCESS: 1,
    NOTIFICATION_RULE_CREATE_SUCCESS: 1,
    NOTIFICATION_RULE_UPDATE_SUCCESS: 1,

    NOTIFICATION_RULE_FETCH_FAIL: 1,
    NOTIFICATION_RULE_CREATE_FAIL: 1,
    NOTIFICATION_RULE_UPDATE_FAIL: 1,

    // Application Health dashboard
    THREAT_FETCH_APPLICATION_HEALTH_START: 1,
    THREAT_FETCH_APPLICATION_HEALTH_SUCCESS: 1,
    THREAT_FETCH_APPLICATION_HEALTH_FAIL: 1,

    // Malicious packages
    FETCH_MALICIOUS_PACKAGES_START: 1,
    FETCH_MALICIOUS_PACKAGES_SUCCESS: 1,
    FETCH_MALICIOUS_PACKAGES_FAIL: 1,

    CREATE_MALICIOUS_PACKAGE_START: 1,
    CREATE_MALICIOUS_PACKAGE_SUCCESS: 1,
    CREATE_MALICIOUS_PACKAGE_FAIL: 1,

    UPDATE_MALICIOUS_PACKAGE_START: 1,
    UPDATE_MALICIOUS_PACKAGE_SUCCESS: 1,
    UPDATE_MALICIOUS_PACKAGE_FAIL: 1,

    DELETE_MALICIOUS_PACKAGES_START: 1,
    DELETE_MALICIOUS_PACKAGES_SUCCESS: 1,
    DELETE_MALICIOUS_PACKAGES_FAIL: 1,

    FETCH_MALICIOUS_PACKAGE_OVERRIDES_START: 1,
    FETCH_MALICIOUS_PACKAGE_OVERRIDES_SUCCESS: 1,
    FETCH_MALICIOUS_PACKAGE_OVERRIDES_FAIL: 1,

    CREATE_MALICIOUS_PACKAGE_OVERRIDE_START: 1,
    CREATE_MALICIOUS_PACKAGE_OVERRIDE_SUCCESS: 1,
    CREATE_MALICIOUS_PACKAGE_OVERRIDE_FAIL: 1,

    DELETE_MALICIOUS_PACKAGE_OVERRIDE_START: 1,
    DELETE_MALICIOUS_PACKAGE_OVERRIDE_SUCCESS: 1,
    DELETE_MALICIOUS_PACKAGE_OVERRIDE_FAIL: 1
});
