/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit';
import {
    checkAvailability,
    createKit,
    fetchAllKits,
    fetchAllPlatforms,
    fetchCiphers,
    fetchContracts,
    fetchKit,
    getKitUploads,
    deleteKitUpload,
    fetchPlatforms,
    updateStatus
} from '../api/kitsThunk';

const initialState = {
    error: null,
    errorMessage: null,
    status: {
        loading: false,
        uploadsLoading: false,
        added: false,
        updated: false
    },
    kits: [],
    ciphers: [],
    platforms: [],
    platformTypes: {},
    applications: null,
    // Setting isRequestAvailable as a tri-state (null, true, false) to detect if the data is available or not.
    isRequestAvailable: null,
    kitStatus: {},
    kit: null,
    kitUploads: {},
    lastAdded: null
};

const kitSlice = createSlice({
    name: 'kits',
    initialState,
    reducers: {
        cleanDownloadCode: (state) => {
            state.kitDownloadCode = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchAllKits.fulfilled,
                (state, action) => {
                    state.kits = action.payload;
                    state.status.loading = false;
                    state.status.added = false;
                }
            )
            .addCase(
                fetchKit.fulfilled,
                (state, action) => {
                    state.kit = action.payload;
                    state.status.loading = false;
                    state.status.added = false;
                }
            )
            .addCase(
                createKit.fulfilled,
                (state, action) => {
                    state.status.added = true;
                    state.lastAdded = action.payload.length ? action.payload[0].kitId : null;
                }
            )
            .addCase(
                fetchCiphers.fulfilled,
                (state, action) => {
                    state.ciphers = action.payload;
                    state.status.loading = false;
                    state.status.added = false;
                }
            )
            .addCase(
                fetchPlatforms.fulfilled,
                (state, action) => {
                    state.platforms = action.payload;
                    state.status.loading = false;
                    state.status.added = false;
                }
            )
            .addCase(
                fetchAllPlatforms.fulfilled,
                (state, action) => {
                    state.platformTypes = action.payload;
                    state.status.loading = false;
                    state.status.added = false;
                }
            )
            .addCase(
                fetchContracts.fulfilled,
                (state, action) => {
                    state.applications = action.payload;
                    state.status.loading = false;
                    state.status.added = false;
                }
            )
            .addCase(
                updateStatus.fulfilled,
                (state, action) => {
                    state.kitStatus = action.payload;
                    // Not changing the loading to false on purpose, as this will trigger a fetch all kits which will set it.
                    state.status.added = false;
                    state.status.updated = true;
                }
            )
            .addCase(
                getKitUploads.fulfilled,
                (state, action) => {
                    state.kitUploads = action.payload;
                    state.status.uploadsLoading = false;
                }
            )
            .addCase(
                deleteKitUpload.fulfilled,
                (state) => {
                    state.status.uploadsLoading = false;
                }
            )
            .addCase(
                checkAvailability.fulfilled,
                (state, action) => {
                    // We don't change on purpose the loading for checkAvailability, as that one is just used to check if the
                    // button is available or not, and no loading is used for that. We make a request to this endpoint at the
                    // same time as we get the kits, so we cannot use the loading variable for both ot them.
                    state.isRequestAvailable = action.payload.isRequestAvailable;
                    state.status.added = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending') && !action.type.includes('kits/upload') && action.type.includes('kit') && !action.type.startsWith('kit/checkAvailability'),
                (state, action) => {
                    if (action.type.includes('kit/getKitUploads') || action.type.includes('kit/deleteKitUpload') || action.type.includes('kits/updateKitFileType')) {
                        state.status.uploadsLoading = true;
                    } else {
                        state.status.loading = true;
                        state.status.added = false;
                        state.status.updated = false;
                    }
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected') && !action.type.includes('kits/upload') && action.type.includes('kit') && !action.type.startsWith('kit/checkAvailability'),
                (state, action) => {
                    if (action.type.includes('kit/getKitUploads') || action.type.includes('kit/deleteKitUpload') || action.type.includes('kits/updateKitFileType')) {
                        state.status.uploadsLoading = false;
                    } else {
                        state.status.loading = false;
                        state.status.added = false;
                        state.status.updated = false;
                        state.error = true;
                        state.errorMessage = action.error.message;
                    }
                }
            );
    }
});

export default kitSlice.reducer;
