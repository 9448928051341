import {createAsyncThunk} from '@reduxjs/toolkit';
import omitEmpty from '../utils/omitEmpty';
import fetchRequest from './utils';

// Disabling the rule in case we need to add another thunk there is no need to change anything
// eslint-disable-next-line import/prefer-default-export
export const getAlerts = createAsyncThunk('appAwareAlerts/getAlerts', async ({
    companyId,
    limit,
    page,
    timestamp,
    start,
    filter
}) => {
    const query = omitEmpty({
        companyId,
        limit,
        page,
        timestamp,
        start
    });
    return fetchRequest('/threats/latest', 'POST', query, {filter});
});
