import React from 'react';
import {
    useLocation,
    useMatch,
    useNavigate
} from 'react-router-dom';

/* eslint-disable require-jsdoc, react/prop-types */
export default function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const match = useMatch(props.matchPattern || '');
        return (
            <Component
                {...props}
                location={location}
                navigate={navigate}
                match={match || {params: {}}}
            />
        );
    }

    return ComponentWithRouterProp;
}
