/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit';
import lodash from 'lodash';
import Storage from '../utils/storage';

import GlobalConsts from '../constants/global';
import tokenUtils from '../api/token';
import {
    login, oneTimePassword, logout, getK6iConfigForVanityDomain,
    getK6iConfigForConfigUrl,
    ssoLogin
} from '../api/authThunk';
import {SESSION_EXPIRED_ERROR_MESSAGE} from '../api/utils';

const storeProfileData = (originalProfile) => {
    localStorage.removeItem(GlobalConsts.STORAGE.keys.user);
    let profile = originalProfile;
    if (profile.remember) {
        profile = lodash.omit(originalProfile, 'token', 'remember', 'salesforceId', 'role', 'company', 'companyId');
        localStorage.setItem(GlobalConsts.STORAGE.keys.user, JSON.stringify(profile));
    }
    localStorage.setItem(GlobalConsts.STORAGE.keys.preferredLanguage, lodash.get(profile, 'language'));
};

const initialState = {
    loading: false,
    token: tokenUtils.get(),
    otpValidated: false,
    otpToken: null,
    sessionExpired: false,
    sso: {
        k6iConfiguration: null,
        k6iConfigurationError: false,
        userProvisioned: false,
        userProvisioningError: null
    }
};

const invalidateAuth = (state) => {
    // Invalidate token
    tokenUtils.remove();
    state.token = null;
    Storage.removeItem(GlobalConsts.NOTIFICATIONS.BANNER_CLOSED);

    // Clear sso config
    state.sso.k6iConfiguration = null;
};

const setToken = (state, token) => {
    tokenUtils.set(token);
    state.token = token;
};

const k6iConfig = (state, action) => {
    const {configuration} = action.payload;
    state.sso.k6iConfiguration = configuration;
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // Only implementing the 'logout' reducer to add the ability to reset the stores of redux
        // Here we can implement extra logout logic
        invalidateAuthentication: invalidateAuth,
        authTokenUpdate: (state, action) => {
            setToken(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                setToken(state, action.payload.token);
                storeProfileData(action.payload);
            })
            .addCase(ssoLogin.fulfilled, (state) => {
                state.sso.userProvisioned = true;
            })
            .addCase(ssoLogin.rejected, (state, action) => {
                state.sso.userProvisioningError = action.error.message;
            })
            .addCase(oneTimePassword.pending, (state) => {
                state.otpValidated = false;
            })
            .addCase(oneTimePassword.fulfilled, (state, action) => {
                state.otpToken = action.payload.token;
                state.otpValidated = true;
            })
            .addCase(logout.fulfilled, invalidateAuth)
            .addCase(logout.rejected, invalidateAuth)
            .addCase(getK6iConfigForVanityDomain.fulfilled, k6iConfig)
            .addCase(getK6iConfigForConfigUrl.fulfilled, k6iConfig)
            .addMatcher((action) => action.type.startsWith('auth/sso-config') && action.type.endsWith('/rejected'), (state) => {
                state.sso.k6iConfigurationError = true;
            })
            .addMatcher((action) => action.type.startsWith('auth') && action.type.endsWith('/pending'), (state) => {
                state.loading = true;
            })
            .addMatcher((action) => action.type.startsWith('auth') && !action.type.endsWith('/pending'), (state) => {
                state.loading = false;
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.error.message === SESSION_EXPIRED_ERROR_MESSAGE, (state) => {
                state.sessionExpired = true;
            });
    }
});

export const {invalidateAuthentication, authTokenUpdate} = authSlice.actions;
export default authSlice.reducer;
