import GlobalConsts from '../constants/global';

const googleAnalytics = GlobalConsts.GOOGLE_ANALYTICS_ENABLED ? window.ga : () => {};

const create = () => {
    googleAnalytics('create', GlobalConsts.GA_TRACKING_ID, 'auto');
};

const sendPageView = (page) => {
    googleAnalytics('send', 'pageview', page);
};

const sendEvent = (type, eventName) => {
    googleAnalytics('send', 'event', type, eventName);
};
const setUser = (userId) => {
    googleAnalytics('set', 'userId', userId);
};

export default {
    create, sendPageView, sendEvent, setUser
};
