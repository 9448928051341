/* eslint-disable no-param-reassign */
import lodash from 'lodash';
import {createSlice} from '@reduxjs/toolkit';

import {
    getPackages, createPackage, updatePackage, deletePackage, getOverrides, createOverride, deleteOverride
} from '../api/maliciousPackagesThunk';

const setPendingState = (state) => {
    const status = {
        loading: true,
        updated: false,
        deleted: false,
        created: false
    };
    state.status = lodash.merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false,
        updated: false,
        deleted: false,
        created: false
    };
    state.status = lodash.merge(state.status, status);
    state.error = action.error.message;
};

const setGetFulfilledState = (state) => {
    const status = {
        loading: false,
        updated: false,
        deleted: false,
        created: false
    };
    state.status = lodash.merge(state.status, status);
};

const initialState = {
    error: null,
    status: {
        loading: false,
        updated: false,
        deleted: false,
        created: false
    },
    packages: [],
    packagesCount: 0,
    overrides: [],
    overridesCount: 0,
    overridesPageCache: {
        selectedCompanyId: null,
        search: ''
    }
};

const maliciousPackagesSlice = createSlice({
    name: 'maliciousPackages',
    initialState,
    reducers: {
        setOverridesPageCache: (state, action) => {
            state.overridesPageCache = {
                ...state.overridesPageCache,
                ...action.payload
            };
        },
        resetState: (state) => {
            state.status.loading = false;
            state.status.updated = false;
            state.status.deleted = false;
            state.status.created = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPackages.fulfilled, (state, action) => {
                setGetFulfilledState(state);
                state.packages = action.payload.rows;
                state.packagesCount = action.payload.count;
            })
            .addCase(createPackage.fulfilled, (state) => {
                setGetFulfilledState(state);
                state.status.created = true;
            })
            .addCase(updatePackage.fulfilled, (state) => {
                state.status.loading = false;
                state.status.updated = true;
                state.status.deleted = false;
            })
            .addCase(deletePackage.fulfilled, (state) => {
                state.status.loading = false;
                state.status.updated = false;
                state.status.deleted = true;
            })
            .addCase(getOverrides.fulfilled, (state, action) => {
                setGetFulfilledState(state);
                state.overrides = action.payload.rows;
                state.overridesCount = action.payload.count;
            })
            .addCase(createOverride.fulfilled, (state) => {
                setGetFulfilledState(state);
                state.status.created = true;
            })
            .addCase(deleteOverride.fulfilled, (state) => {
                state.status.loading = false;
                state.status.updated = false;
                state.status.deleted = true;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('maliciousPackages'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('maliciousPackages'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export const {setOverridesPageCache, resetState} = maliciousPackagesSlice.actions;
export default maliciousPackagesSlice.reducer;
