import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getLicenses = createAsyncThunk('licenses/getLicenses', async (params) => fetchRequest('/licenses', 'GET', params));

export const getLicense = createAsyncThunk('licenses/getLicense', async (id) => fetchRequest(`/me/licenses/${id}`, 'GET', null));

export const updateLicense = createAsyncThunk('licenses/updateLicense',
    async (body, thunkAPI) => {
        const {id, isAdmin, data} = body;
        await fetchRequest(isAdmin ? `/licenses/${id}` : `/me/licenses/${id}`, 'PUT', null, data);
        thunkAPI.dispatch(getLicenses());
    });

export const createLicense = createAsyncThunk('licenses/createLicense',
    async (body, thunkAPI) => {
        const {
            contractId, releaseId, productId, data
        } = body;
        await fetchRequest(`/me/contracts/${contractId}/products/${productId}/releases/${releaseId}/licenses`, 'POST', null, data);
        thunkAPI.dispatch(getLicenses());
    });

export const deleteLicense = createAsyncThunk('licenses/deleteLicense',
    async (id, thunkAPI) => {
        await fetchRequest(`/licenses/${id}`, 'DELETE', null);
        thunkAPI.dispatch(getLicenses());
    });

export const regenerateOfflineLicense = createAsyncThunk('licenses/regenerateOfflineLicense',
    async (id, thunkAPI) => {
        await fetchRequest(`/licenses/${id}`, 'PATCH', {id});
        thunkAPI.dispatch(getLicenses());
    });
