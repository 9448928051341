import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {DotTypography, DotButton} from '@digital-ai/dot-components';
import {StyledAlertBanner} from './NotificationBanner.styles';
import {NOTIFICATION_TYPES, ROUTES} from '../../constants/global';
import {getBannerMessageInfo} from '../../stores/UserMessagesSlice';

const NotificationBanner = () => {
    const navigate = useNavigate();
    const bannerMessageInfo = useSelector(getBannerMessageInfo);

    const viewButton = <DotButton
        dense
        type='text'
        onClick={() => navigate(`${ROUTES.notifications.route}/${bannerMessageInfo.id}`)}
        data-testid='notification-banner__action-button'
    >
        View
    </DotButton>;

    return bannerMessageInfo && <StyledAlertBanner
        action={viewButton}
        width='95%'
        severity={NOTIFICATION_TYPES[bannerMessageInfo.message.type].severity}
        data-testid='notification-banner__container'
    >
        <DotTypography
            variant='h5'
            data-testid='notification-banner__label'
        >
            {NOTIFICATION_TYPES[bannerMessageInfo.message.type].label}
        </DotTypography>
        {bannerMessageInfo.message.title}
    </StyledAlertBanner>;
};

export default NotificationBanner;
