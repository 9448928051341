import styled from 'styled-components';
import {DotActionToolbar} from '@digital-ai/dot-components';

export const BreadcrumbAction = styled.section`
    margin-left: auto;
    margin-right: 1.5rem;
`;

export const PageContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    height: ${({banner}) => (banner ? '85%' : '95%')};;
    width: ${({width}) => width};
    margin: ${({margin}) => margin};
`;

export const PageTitle = styled.section`
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .left {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
    }

    .right {
        flex: 4;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
    }

    .companies-picker {
        width: 16rem;
    }

    &.dot-action-toolbar {
        border-bottom: unset;
    }
`;

export const PageContentContainer = styled.section`
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
`;

export const StyledActionToolbar = styled(DotActionToolbar)`
    padding-right: 0px;
`;
