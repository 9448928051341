import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getMessages = createAsyncThunk('messages/getMessages', async (params) => fetchRequest('/messages', 'GET', params));

export const sendMessage = createAsyncThunk(
    'messages/sendMessage',
    async (id, thunkAPI) => {
        await fetchRequest(`/messages/${id}`, 'PATCH');
        thunkAPI.dispatch(getMessages());
    }
);

export const deleteMessage = createAsyncThunk(
    'messages/deleteMessage',
    async (id, thunkAPI) => {
        await fetchRequest(`/messages/${id}`, 'DELETE');
        thunkAPI.dispatch(getMessages());
    }
);

export const createMessage = createAsyncThunk('messages/createMessage', async (body) => fetchRequest('/messages', 'POST', null, body));

export const updateMessage = createAsyncThunk(
    'messages/updateMessage',
    async (body, thunkAPI) => {
        const {id, ...data} = body;
        await fetchRequest(`/messages/${id}`, 'PUT', null, data);
        thunkAPI.dispatch(getMessages());
    }
);
