import styled, {css} from 'styled-components';
import {
    DotMenu, DotSidebar
} from '@digital-ai/dot-components';

export const rootClassName = 'dot-nav-list';
export const mainContentClassName = 'main-content';
export const sidebarWidth = 256;
export const navBreakpoint = 'md';
export const appToolbarHeight = '3rem';

export const StyledMenu = styled(DotMenu)`
    z-index: 1201;
    padding-top: ${(props) => (props.anchorEl?.classList.contains('profile-menu') ? '8px' : '0px')};
`;

export const StyledMenuElementDiv = styled.div`
    align-items: flex-start;
    display: flex;
    gap: 20px;
    width: 100%;
`;

export const StyledMenuElementLeftIconDiv = styled.div`
    flex-basis: 40px;
    justify-content: flex-start;
`;

export const StyledMenuElementTitleDiv = styled.div`
    justify-content: flex-start;
    width: 80%;
    min-width: 150px;
`;

export const StyledMenuElementRightIconDiv = styled.div`
    flex-basis: 40px;
    justify-content: flex-end;
`;

export const StyledSidebar = styled(DotSidebar)`
  ${({theme}) => css`
    &.dot-sidebar {
      hr.MuiDivider-root {
        margin: ${theme.spacing(0.5, 0)};
      }
      a {
        &.account-edit-link {
          color: ${theme.palette.grey[500]};
        }
        text-decoration: none;
      }
      .account-avatar-and-name {
        margin-left: ${theme.spacing(2)};
        margin-top: ${theme.spacing(0.5)};
      }
      header {
        .dot-avatar {
          display: none;
        }
        height: 32px;
      }
      .go-back {
        .go-back-button {
          margin: 12px;
          padding: 2px;
          color: #f2f4f5;
          background-color: ${theme.palette.layer.n500};

          &:hover {
            background-color: ${theme.palette.layer.n800};
          }

          &:focus-visible {
            box-shadow: 0 0 0 2px ${theme.palette.layer.n0},
              0 0 0 4px ${theme.palette.layer.n900};
          }
        }

        .back-button-text {
          margin-left: 24px;
        }
      }
      .account-name {
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
        .dot-typography {
          display: flex;
          align-items: center;
        }
        .dot-avatar {
          margin-right: ${theme.spacing(2)};
        }
      }
      .dot-list-item .dot-typography {
        margin-left: ${theme.spacing(2)};
      }
      .dot-list-item .dot-icon {
        display: inherit;
      }
      .dot-drawer > .dot-drawer-paper {
        top: ${appToolbarHeight};
      }
    }
    &.dot-sidebar.collapsed {
      .account-avatar-and-name {
        margin-left: 7px;
      }
      .collapsable-divider {
        display: none;
      }
    }
  `}
`;

export const StyledMainContent = styled.main`
    &.${mainContentClassName} {
        display: flex;
        flex-direction: row;
        height: calc(100vh - ${appToolbarHeight});
        overflow: hidden;
        position: absolute;
        top: ${appToolbarHeight};
        width: 100vw;
    }
`;

export const NavigationPageContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    ${({theme}) => css`
        width: 100%;
        ${theme.breakpoints.up(navBreakpoint)} {
          width: calc(100% - ${(props) => (props.collapsed ? '50px' : `${sidebarWidth}px`)});
        }
    `};
`;

export const NavigationPageContent = styled.section`
    flex: 1;
    padding: 0px 0px 24px;
    overflow: hidden;
`;
