import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getApplications = createAsyncThunk('appManage/getApplications', async (params) => fetchRequest('/app-manage', 'GET', params));

export const updateApplication = createAsyncThunk(
    'appManage/updateApplication',
    async (body, thunkAPI) => {
        const application = await fetchRequest('/app-manage', 'POST', null, body);
        thunkAPI.dispatch(getApplications());
        return application;
    }
);

export const deleteApplication = createAsyncThunk(
    'appManage/deleteApplication',
    async ({applicationId, companyId}, thunkAPI) => {
        const application = await fetchRequest('/app-manage', 'DELETE', {companyId, applicationId});
        thunkAPI.dispatch(getApplications());
        return application;
    }
);

export const getApplicationProperties = createAsyncThunk(
    'appManage/getApplicationProperties',
    async ({label, applicationId, companyId}) => fetchRequest(
        '/threat-analytics/applicationProperties',
        'GET',
        {label, applicationId, companyId}
    )
);

export const getAllApplicationProperties = createAsyncThunk(
    'appManage/getAllApplicationProperties',
    async ({companyId}) => fetchRequest(
        '/threat-analytics/applicationProperties/list',
        'GET',
        {companyId}
    )
);

export const upsertApplicationProperties = createAsyncThunk(
    'appManage/upsertApplicationProperties',
    async ({
        label, applicationId, companyId, properties
    }) => fetchRequest(
        '/threat-analytics/applicationProperties',
        'POST',
        {label, applicationId, companyId},
        {properties}
    )
);

export const deleteApplicationProperties = createAsyncThunk(
    'appManage/deleteApplicationProperties',
    async ({
        label, applicationId, companyId, guardType
    }) => fetchRequest(
        '/threat-analytics/applicationProperties',
        'DELETE',
        {
            label, applicationId, companyId, guardType
        }
    )
);
