import {configureStore, combineReducers} from '@reduxjs/toolkit';

import {logout} from '../api/authThunk';

import appReducer from './AppSlice';
import authReducer from './AuthSlice';
import appFeatureReducer from './AppFeatureSlice';
import profileReducer from './ProfileSlice';
import companiesReducer from './CompaniesSlice';
import applicationsReducer from './ApplicationsSlice';
import licensesReducer from './LicensesSlice';
import releaseReducer from './ReleaseSlice';
import maliciousPackagesReducer from './MaliciousPackagesSlice';
import contractsReducer from './ContractsSlice';
import allowedCountriesReducer from './AllowedCountriesSlice';
import usersReducer from './UsersSlice';
import productsReducer from './ProductsSlice';
import tokenReducer from './TokenSlice';
import oauthReducer from './OAuthSlice';
import messagesReducer from './MessagesSlice';
import userMessagesReducer from './UserMessagesSlice';
import appManageReducer from './AppManageSlice';
import apaasReducer from './ApaasSlice';
import apaasHistoryReducer from './ApaasHistorySlice';
import protectionHistoryReducer from './ProtectionHistorySlice';
import appAwareReducer from './AppAwareSlice';
import appAwareAlertsReducer from './AppAwareAlertsSlice';
import appAwareEventsReducer from './AppAwareEventsSlice';
import uploadManagerReducer from './UploadManagerSlice';
import kitsReducer from './KitsSlice';
import fileShareReducer from './FileShareSlice';

const ACTIONS_RESET_STORES = [logout.fulfilled.type, logout.rejected.type];

// All reducers from the slices should be registered here
const combinedReducers = combineReducers({
    appReducer,
    authReducer,
    usersReducer,
    appFeatureReducer,
    companiesReducer,
    applicationsReducer,
    licensesReducer,
    releaseReducer,
    profileReducer,
    maliciousPackagesReducer,
    contractsReducer,
    allowedCountriesReducer,
    productsReducer,
    tokenReducer,
    oauthReducer,
    messagesReducer,
    userMessagesReducer,
    appManageReducer,
    apaasReducer,
    apaasHistoryReducer,
    protectionHistoryReducer,
    appAwareReducer,
    appAwareAlertsReducer,
    appAwareEventsReducer,
    uploadManagerReducer,
    kitsReducer,
    fileShareReducer
});

// Root reducer will receive all the redux store changes, in case the action.type is equal to ACTION_RESET_STORES
// the state will be set to undefined, in order to force redux to recreate the whole store tree
// using the initial state of each slice. This is intented to be used when logout occurs.
const rootReducer = (state, action) => {
    const lastState = ACTIONS_RESET_STORES.includes(action.type) ? undefined : state;
    return combinedReducers(lastState, action);
};

const storeFactory = ({preloadedState = null, middleware = null}) => {
    const options = {
        reducer: rootReducer
    };

    if (preloadedState) {
        options.preloadedState = preloadedState;
    }
    if (middleware) {
        options.middleware = middleware;
    }
    return configureStore(options);
};

export default storeFactory;
