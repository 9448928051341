import {CSSTransition} from 'react-transition-group';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import './FadeInTransition.css';

import isInternetExplorer from '../../utils/ie';

class FadeInTransition extends Component {
    static propTypes = {
        children: PropTypes.any
    };

    static defaultProps = {
        children: undefined
    }

    renderEmptyContent() {
        return (<span>{this.props.children}</span>);
    }

    renderTransitionContent() {
        return (
            <CSSTransition
                in
                appear
                classNames="fadeIn"
                timeout={200}
            >
                <>{this.props.children}</>
            </CSSTransition>
        );
    }

    render() {
        return (
            isInternetExplorer ? this.renderEmptyContent() : this.renderTransitionContent()
        );
    }
}
export default FadeInTransition;
