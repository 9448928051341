/* eslint-disable no-param-reassign */
import {merge} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';

import {
    getDefinitions,
    getDashboardData,
    getEvents,
    getTable,
    getNotificationConfiguration,
    updateNotificationConfiguration
} from '../api/appAwareThunk';

const setPendingState = (state) => {
    state.error = null;
    const status = {
        notificationUpdated: false,
        loading: true
    };
    state.status = merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        notificationUpdated: false,
        loading: false
    };
    state.status = merge(state.status, status);
    state.error = action.error.message;
};

const setGetFulfilledState = (state) => {
    const status = {
        loading: false
    };
    state.status = merge(state.status, status);
};

const initialState = {
    error: null,
    status: {
        loading: false,
        notificationUpdated: false,
        // The following 3 might not be needed once we migrate the page to new UI THREAT-3336
        skipUpdate: false,
        toggleLoading: false
    },

    definitions: {},
    notificationConfiguration: {},
    data: {
        getEvents: [],
        dashboard: [],
        getTable: []
    }
};

const appAwareSlice = createSlice({
    name: 'appAware',
    initialState,
    reducers: {
        resetNotificationConfiguration: (state) => {
            state.notificationConfiguration = {};
            state.status.notificationUpdated = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDefinitions.fulfilled, (state, action) => {
                setGetFulfilledState(state);
                state.definitions = action.payload;
            })
            .addCase(getNotificationConfiguration.fulfilled, (state, action) => {
                setGetFulfilledState(state);
                state.notificationConfiguration = action.payload;
            })
            .addCase(updateNotificationConfiguration.fulfilled, (state, action) => {
                setGetFulfilledState(state);
                state.status.notificationUpdated = true;
                state.notificationConfiguration = action.payload;
            })
            .addCase(getDashboardData.fulfilled, (state, action) => {
                setGetFulfilledState(state);
                state.data.dashboard = action.payload;
            })
            .addCase(getEvents.fulfilled, (state, action) => {
                setGetFulfilledState(state);
                state.data.getEvents = action.payload;
            })
            .addCase(getTable.fulfilled, (state, action) => {
                setGetFulfilledState(state);
                state.data.getTable = action.payload;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('appAware/'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('appAware/'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export const {resetNotificationConfiguration} = appAwareSlice.actions;
export default appAwareSlice.reducer;
