import {createAsyncThunk} from '@reduxjs/toolkit';

import {makeRequestSaveDeliverySettings, makeRequestSaveProfile} from './profileAPI';
import {utoa} from '../utils/convert';

export const saveProfile = createAsyncThunk('profile/saveProfile',
    async (body, thunkAPI) => {
        const currentProfile = thunkAPI.getState().profileReducer.profile;
        const data = {...body};
        Object.keys(data).forEach((key) => {
            if (!data[key] || data[key] === currentProfile[key] || data[key] === '' || key === 'confirmPassword') {
                delete data[key];
                return;
            }

            if (['currentPassword', 'createPassword'].includes(key)) {
                data[key] = utoa(data[key]);
            }

            if (key === 'email' && data[key]) {
                data[key] = data[key].toLowerCase();
            }
        });
        await makeRequestSaveProfile(data);
        return body;
    }
);

export const saveDeliverySettings = createAsyncThunk('profile/saveDeliverySettings',
    async (body) => makeRequestSaveDeliverySettings(body)
);
