import PropTypes from 'prop-types';
import React, {Component} from 'react';

import lodash from 'lodash';
import classNames from 'classnames';

import UploadManagerStore from '../../stores/UploadManagerStore';

import DropZone from '../DropZone';

import {
    StyledUploadManagerDiv
} from './UploadManager.styles';

class UploadManager extends Component {
    static propTypes = {
        children: PropTypes.any
    };

    static defaultProps = {
        children: undefined
    }


    constructor() {
        super();

        this.subscriptions = [];
        this.state = {
            instances: []
        };
    }

    componentDidMount() {
        this.subscriptions.push(UploadManagerStore.addListener(this.__onUploadManagerStoreChange.bind(this)));
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => {
            subscription.remove();
        });
    }

    __onUploadManagerStoreChange() {
        if (UploadManagerStore.isLoading()) return;
        const instances = this.__createInstances(UploadManagerStore.getInstances());

        this.setState({
            instances: instances || this.state.instances
        });
    }

    __createInstances(props) {
        const instances = [];

        lodash.mapValues(props, (params, key) => {
            instances.push(
                <DropZone {...params} className="DropZoneCore" key={key}>
                    {(() => {
                        if (params.hasChildren) {
                            return [<span key="child" />];
                        }
                        return true;
                    })()}
                </DropZone>
            );
        });

        return instances;
    }

    render() {
        return (
            <StyledUploadManagerDiv className={classNames('UploadManager')}>
                {this.props.children}
                <div className="UploadManager--wrapper">{this.state.instances}</div>
            </StyledUploadManagerDiv>
        );
    }
}

export default UploadManager;
