import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getAllowedCountries = createAsyncThunk('allowedCountries/getAllowedCountries', async (params) => fetchRequest('/allowed-countries', 'GET', params));

export const addCountry = createAsyncThunk('allowedCountries/addCountry',
    async (body, thunkAPI) => {
        await fetchRequest(`/allowed-countries`, 'POST', null, body);
        thunkAPI.dispatch(getAllowedCountries());
    });

export const deleteCountry = createAsyncThunk('allowedCountries/deleteCountry',
    async (id, thunkAPI) => {
        await fetchRequest(`/allowed-countries/${id}`, 'DELETE', null);
        thunkAPI.dispatch(getAllowedCountries());
    });
