/* eslint-disable no-param-reassign */
import {merge} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';

import {
    getUserApps, deleteApp, stopAppProtection, getAllApps, protectApp, updateApp, getApp, downloadFile
} from '../api/apaasThunk';

const setFulfilledState = (state) => {
    const status = {
        loading: false,
        deleted: false,
        protected: false,
        updated: false,
        removeAppShell: false,
        notFound: false
    };
    state.status = merge(state.status, status);
    state.error = null;
};

const initialState = {
    status: {
        loading: false,
        deleted: false,
        protected: false,
        updated: false,
        removeAppShell: false,
        notFound: false
    },
    applications: [],
    application: null,
    error: null
};

const apaasSlice = createSlice({
    name: 'apaas',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserApps.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.applications = action.payload;
            })
            .addCase(getAllApps.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.applications = action.payload;
            })
            .addCase(deleteApp.fulfilled, (state) => {
                setFulfilledState(state);
                state.status.deleted = true;
                state.status.removeAppShell = true;
            })
            .addCase(stopAppProtection.fulfilled, (state) => {
                setFulfilledState(state);
                state.status.removeAppShell = true;
            })
            .addCase(protectApp.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.status.protected = true;
                state.application = action.payload;
            })
            .addCase(updateApp.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.status.updated = true;
                state.application = action.payload;
            })
            .addCase(getApp.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.application = action.payload;
            })
            .addCase(downloadFile.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.application = action.payload;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('apaas'), (state) => {
                state.status.loading = true;
                state.status.deleted = false;
                state.error = null;
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('apaas'), (state, action) => {
                state.status.loading = false;
                state.error = action.error.message;
            });
    }
});

export default apaasSlice.reducer;
