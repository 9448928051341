/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit';
import {
    getRelease,
    getReleases,
    getUserReleases,
    getUserReleasesByProductKey,
    deleteRelease,
    updateRelease,
    createRelease,
    getReleaseUploads
} from '../api/releaseThunk';

const initialState = {
    error: null,
    status: {
        loading: false,
        uploadsLoading: false,
        releaseLoading: false,
        created: false,
        updated: false,
        deleted: false
    },
    releases: [],
    release: {},
    userReleases: [],
    userReleasesByProductKey: [],
    releaseUploads: []
};

const releaseSlice = createSlice({
    name: 'release',
    initialState,
    reducers: {
        resetRelease: (state) => {
            state.release = {};
            state.releaseUploads = [];
        },
        resetError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRelease.fulfilled, (state, action) => {
                state.status.releaseLoading = false;
                state.status.created = false;
                state.status.updated = false;
                state.release = action.payload;
            })
            .addCase(getReleases.fulfilled, (state, action) => {
                state.status.created = false;
                state.status.updated = false;
                state.status.deleted = false;
                state.status.loading = false;
                state.releases = action.payload;
            })
            .addCase(getUserReleases.fulfilled, (state, action) => {
                state.status.created = false;
                state.status.updated = false;
                state.status.loading = false;
                state.userReleases = action.payload;
            })
            .addCase(getUserReleasesByProductKey.fulfilled, (state, action) => {
                state.status.created = false;
                state.status.updated = false;
                state.status.loading = false;
                state.userReleasesByProductKey = action.payload;
            })
            .addCase(deleteRelease.fulfilled, (state) => {
                state.status.created = false;
                state.status.updated = false;
                state.status.deleted = true;
            })
            .addCase(updateRelease.fulfilled, (state) => {
                state.status.updated = true;
            })
            .addCase(createRelease.fulfilled, (state) => {
                state.status.created = true;
            })
            .addCase(getReleaseUploads.fulfilled, (state, action) => {
                state.releaseUploads = action.payload;
                state.status.uploadsLoading = false;
            })
            .addMatcher(
                (action) => action.type.endsWith('/pending') && action.type.includes('release') && !action.type.includes('release/initReleaseUpload'),
                (state, action) => {
                    if (['release/getReleaseUploads', 'release/deleteReleaseUpload', 'release/updateReleaseFileType'].some((type) => action.type.includes(type))) {
                        state.status.uploadsLoading = true;
                    } else if (['release/getRelease', 'release/updateRelease', 'release/createRelease'].some((type) => action.type.includes(type))
                    && !action.type.includes('release/getReleases')
                    ) {
                        state.status.releaseLoading = true;
                    } else {
                        state.status.loading = true;
                    }
                    state.status.created = false;
                    state.status.updated = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected') && action.type.includes('release') && !action.type.includes('release/initReleaseUpload'),
                (state, action) => {
                    if (['release/getReleaseUploads', 'release/deleteReleaseUpload', 'release/updateReleaseFileType'].some((type) => action.type.includes(type))) {
                        state.status.uploadsLoading = false;
                    } else if (['release/getRelease', 'release/updateRelease', 'release/createRelease'].some((type) => action.type.includes(type))
                    && !action.type.includes('release/getReleases')
                    ) {
                        state.status.releaseLoading = false;
                    } else {
                        state.status.loading = false;
                    }
                    state.status.created = false;
                    state.status.updated = false;
                    state.error = action.error.message;
                }
            );
    }
});

const {reducer} = releaseSlice;
export const {resetRelease, resetError} = releaseSlice.actions;
export default reducer;
