/**
 * Wrapper for session storage
 * Allows to retain variable type when retrieving data from storage
 * Essentially solves problem of
 *   sessionStorage.setItem('test', 1);
 *   sessionStorage.getItem('test') === 1; // false
 * As opposed to:
 *   storage.setItem('test', 1);
 *   storage.getItem('test') === 1; // true
 */
export default {
    getItem: (key) => JSON.parse(window.sessionStorage.getItem(key)),
    setItem: (key, value) => window.sessionStorage.setItem(key, JSON.stringify(value)),
    removeItem: (key) => window.sessionStorage.removeItem(key)
};
