import {createAsyncThunk} from '@reduxjs/toolkit';

import fetchRequest, {fetchRequestFromUrl} from './utils';
import {utoa} from '../utils/convert';

export const login = createAsyncThunk(
    'auth/login',
    async (params) => {
        const email = utoa(params.email);
        const password = utoa(params.password);
        const {remember} = params;
        // Still need to add some logic to support redirection on login
        return fetchRequest('/login', 'POST', null, {email, password, remember}, true);
    }
);

export const ssoLogin = createAsyncThunk(
    'auth/ssoLogin',
    async ({code, realm, timezone}) => fetchRequest(`/sso/login`, 'POST', null, {code, realm, timezone}, true)
);

export const oneTimePassword = createAsyncThunk('auth/otp', async (code) => fetchRequest('/otp', 'POST', null, {code}), true);

export const validate = createAsyncThunk('auth/validate', async () => fetchRequest('/me', 'GET'));

export const setup = createAsyncThunk(
    'auth/setup',
    async ({password, track, otpToken}) => {
        const base64Password = btoa(password);
        const language = 'en';
        return fetchRequest('/setup', 'POST', null, {password: base64Password, language, track}, false, otpToken);
    }
);

export const reset = createAsyncThunk(
    'auth/reset',
    async (email) => fetchRequest('/reset', 'POST', null, {email: btoa(email)})
);

export const logout = createAsyncThunk('auth/logout', async () => fetchRequest('/logout', 'POST'));

export const getK6iConfigForVanityDomain = createAsyncThunk(
    'auth/sso-config/getK6iConfigForVanityDomain',
    async (vanityDomain) => fetchRequest(`/sso/configuration?vanityDomain=${vanityDomain}`, 'GET', null, null, true)
);

export const getK6iConfigForConfigUrl = createAsyncThunk(
    'auth/sso-config/getK6iConfigForConfigUrl',
    async (configUrl) => fetchRequestFromUrl(configUrl, 'GET', null, null, true)
);
