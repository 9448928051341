/* eslint react/no-string-refs:warn */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import * as ReactDOM from 'react-dom';

import lodash from 'lodash';

import classNames from 'classnames';

import Upload from './Upload';
import GlobalConsts from '../../constants/global';

import token from '../../api/token';
import Key from '../../utils/key';

const {
    ERROR_NOT_ALLOWED_EXTENSIONS,
    ERROR_FILE_TOO_SMALL,
    ERROR_FILE_TOO_BIG,
    ERROR_FILE_EXISTS,
    ERROR_FILE_IS_UPLOADING_AUTOMATICALLY,
    ERROR_MAX_FILES_EXCEEDED,
    ERROR_FILE_CONTENT_IS_NOT_A_STRING,
    ERROR_NO_INIT_DATA_FROM_SERVER
} = GlobalConsts.UPLOAD_ERRORS;

class DropZone extends Component {
    static propTypes = {
        returns: PropTypes.oneOf(['content']),
        minBytes: PropTypes.number,
        maxBytes: PropTypes.number,
        extensions: PropTypes.array,
        fileData: PropTypes.object,
        simultaneousUploads: PropTypes.number,
        chunkSize: PropTypes.number,
        uploadMethod: PropTypes.oneOf(['POST', 'PUT']),
        file: PropTypes.object,
        retryAbility: PropTypes.bool,
        firstChunkInitialize: PropTypes.bool,
        attempts: PropTypes.number,
        triggers: PropTypes.any,
        hasChildren: PropTypes.bool,

        // actions
        // abort specific file name or null to stop aborting
        abort: PropTypes.string,
        pause: PropTypes.string,
        resume: PropTypes.string,

        // callbacks
        onInit: PropTypes.func,
        onStart: PropTypes.func,
        onProgress: PropTypes.func,
        onComplete: PropTypes.func,
        onError: PropTypes.func,
        onAbort: PropTypes.func,
        onPause: PropTypes.func,
        onResume: PropTypes.func,
        className: PropTypes.string,
        children: PropTypes.any
    };

    constructor(props) {
        super(props);

        this.current = {};
        this.state = {
            dropZoneRefresh: 1
        };
        this.inputs = {};
        this.upload = new Upload();
    }

    componentDidMount() {
        this.upload.init(
            lodash.merge(
                {},
                lodash.omit(this.props, 'browseTrigger', 'children', 'triggers'),
                {onError: this.__onError.bind(this), token: token.get()}
            ),
            this.props.file
        );

        if (!lodash.isEmpty(this.props.triggers) && this.upload) {
            this.__manageTriggers(this.props.triggers, this.props.hasChildren);
        }
    }

    componentDidUpdate(prevProps) {
        if (!lodash.isEmpty(this.props.abort) && prevProps.abort !== this.props.abort) this.upload.abort(this.props.abort);
        if (!lodash.isEmpty(this.props.pause) && prevProps.pause !== this.props.pause) this.upload.pause(this.props.pause);
        if (!lodash.isEmpty(this.props.resume) && prevProps.resume !== this.props.resume) this.upload.resume(this.props.resume);
        if (!lodash.isEmpty(this.props.fileData) && prevProps.fileData !== this.props.fileData) this.upload.run(this.props.fileData);

        if (prevProps.triggers !== this.props.triggers || prevProps.hasChildren !== this.props.hasChildren) {
            this.__manageTriggers(this.props.triggers, this.props.hasChildren);
        }

        if (!lodash.isEqual(prevProps.extensions, this.props.extensions) && prevProps.extensions !== this.props.extensions) {
            this.upload.resumable.opts.fileType = this.props.extensions;
        }

        if (this.props.file && this.props.file !== prevProps.triggers) this.upload.addFile(this.props.file);
    }

    componentWillUnmount() {
        this.upload.cancel();
    }

    __renderDropZone() {
        return (<div
            className={classNames('DropZone--description', {'DropZone--description__hidden': this.props.children})}
            key={Key.generate()}
        >

            {'Drop file here,'}<br />{'or click to select'}
        </div>);
    }

    __manageTriggers(triggers, hasChildren) {
        const isLoading = this.upload.isLoading();

        if (lodash.isEmpty(triggers) || (this.state.isLoading === isLoading && this.__browsesHasChildren(triggers) && hasChildren)) {
            return;
        }

        const browses = [];
        this.setState({isLoading});
        this.upload.unassignTriggers(triggers.browses, triggers.drops);

        if (triggers.replace) {
            triggers.browses.forEach((browse) => {
                const {wrapper} = browse;

                ReactDOM.render(React.cloneElement(browse.trigger, {
                    className: classNames({'DropZone--uploadTriggerDisabled': isLoading})
                }), wrapper);

                browses.push(wrapper.firstChild);
            });
        }

        if (!isLoading && !triggers.preventAssign) {
            this.upload.assignTriggers((browses.length && browses[0]) ? browses : triggers.browses, triggers.drops);
        }
    }

    __browsesHasChildren(triggers) {
        let hasChildren = false;
        triggers.browses.forEach((browse) => {
            if (!hasChildren) {
                hasChildren = browse.wrapper.hasChildNodes();
            }
        });

        return hasChildren;
    }

    __onError(type, data) {
        let message;

        // handle default errors
        switch (type) {
            case ERROR_NOT_ALLOWED_EXTENSIONS: {
                message = 'Invalid file type';
                break;
            }
            case ERROR_FILE_TOO_SMALL:
                message = `${'File is too small. Allowed minimum size is'} ${data.allowedSize}`;
                break;

            case ERROR_FILE_TOO_BIG:
                message = `${'Maximum file size exceeded. Allowed maximum size is'} ${data.allowedSize}`;
                break;

            case ERROR_FILE_EXISTS:
                message = 'File already uploaded';
                break;

            case ERROR_FILE_IS_UPLOADING_AUTOMATICALLY:
                message = `File ${data.file.name} downloading from external source in progress`;
                break;

            case ERROR_MAX_FILES_EXCEEDED:
                message = data > 1
                    ? `Files can only be uploaded ${data} at a time`
                    : 'Files can only be uploaded one at a time';
                break;

            case ERROR_FILE_CONTENT_IS_NOT_A_STRING:
                message = 'File content must be an ASCII string not binary. Please, try again.';
                break;

            case ERROR_NO_INIT_DATA_FROM_SERVER:
                message = 'No initial data from upload part. Please, try again.';
                break;

            default: {
                const filename = data && data.file ? data.file.filename : undefined;
                message = `Error during upload ${filename}. Please, try again`;
            }
        }

        if (this.props.onError) {
            this.props.onError(message, data.file);
        }
    }

    render() {
        return (
            <div className={classNames('DropZone', this.props.className)}>
                <div className="DropZone--pane">
                    <div className="DropZone--dropArea" ref="dropZoneChildren" key={this.state.dropZoneRefresh}>
                        {
                            !this.props.children
                                ? this.__renderDropZone()
                                : this.props.children
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default DropZone;
