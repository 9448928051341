import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getAllApps = createAsyncThunk('apaas/getApps', async (params) => fetchRequest('/apaas/apps', 'GET', params));

export const getApp = createAsyncThunk('apaas/getApp', async (id) => fetchRequest(`/apaas/apps/${id}`, 'GET'));

export const downloadFile = createAsyncThunk('apaas/downloadFile', async (id) => fetchRequest(`/apaas/apps/${id}/downloadAccessCode`, 'POST'));

export const getUserApps = createAsyncThunk('apaas/getUserApps', async (params) => fetchRequest('/me/apaas/app', 'GET', params));

export const stopAppProtection = createAsyncThunk(
    'apaas/stopAppProtection',
    async (id, thunkAPI) => {
        await fetchRequest(`/apaas/apps/${id}/stop`, 'POST');
        thunkAPI.dispatch(getAllApps());
    }
);

export const updateApp = createAsyncThunk(
    'apaas/updateApp',
    async (body, thunkAPI) => {
        const {id, ...data} = body;
        await fetchRequest(`/apaas/apps/${id}`, 'PUT', null, data);
        thunkAPI.dispatch(getAllApps());
    }
);

export const deleteApp = createAsyncThunk(
    'apaas/deleteApp',
    async (id, thunkAPI) => {
        await fetchRequest(`/apaas/apps/${id}`, 'DELETE');
        thunkAPI.dispatch(getAllApps());
    }
);

export const protectApp = createAsyncThunk(
    'apaas/protectApp',
    async (body, thunkAPI) => {
        const {id, ...data} = body;
        await fetchRequest(`/apaas/protect/${id}/start`, 'PUT', null, data);
        thunkAPI.dispatch(getAllApps());
    }
);
