// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../global/variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fadeIn-appear {\n    opacity: 0;\n}\n\n.fadeIn-appear-active {\n    opacity: 1;\n    transition: opacity 200ms;\n}\n", "",{"version":3,"sources":["webpack://./src/components/FadeInTransition/FadeInTransition.css"],"names":[],"mappings":"AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,yBAAyB;AAC7B","sourcesContent":["@import '../../global/variables.css';\n\n.fadeIn-appear {\n    opacity: 0;\n}\n\n.fadeIn-appear-active {\n    opacity: 1;\n    transition: opacity 200ms;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
