/* eslint-disable no-param-reassign */
import {merge} from 'lodash';
import {createSlice, createSelector} from '@reduxjs/toolkit';

import {NOTIFICATION_TYPES} from '../constants/global';
import {
    getMessages, deleteMessage, markMessageRead
} from '../api/userMessagesThunk';

const setPendingState = (state) => {
    const status = {
        loading: true,
        deleted: false,
        marked: false
    };
    state.status = merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false,
        deleted: false,
        marked: false
    };
    state.status = merge(state.status, status);
    state.error = true;
    state.errorMessage = action.error.message;
};

const setFulfilledState = (state) => {
    const status = {
        loading: false,
        deleted: false,
        marked: false
    };
    state.status = merge(state.status, status);
    state.error = false;
    state.errorMessage = null;
};

const initialState = {
    error: null,
    errorMessage: null,
    status: {
        loading: false,
        deleted: false,
        marked: false
    },
    data: []
};

const userMessagesSlice = createSlice({
    name: 'userMessages',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMessages.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.data = action.payload;
            })
            .addCase(markMessageRead.fulfilled, (state) => {
                setFulfilledState(state);
                state.status.marked = true;
            })
            .addCase(deleteMessage.fulfilled, (state) => {
                setFulfilledState(state);
                state.status.deleted = true;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('userMessages'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('userMessages'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export const getUnviewedCount = createSelector((state) => state.userMessagesReducer.data, (data) => data.filter((item) => !item.isViewed).length);
export const getBannerMessageInfo = createSelector(
    (state) => state.userMessagesReducer.data,
    (data) => data
        .filter((item) => (!item.isViewed && (item.message.type === NOTIFICATION_TYPES.criticalAnnouncements.key || item.banner)))
        .sort((a, b) => {
            const {key: criticaltype} = NOTIFICATION_TYPES.criticalAnnouncements;

            // Banner priority is given to "Critical Announcements" notifications and recency.
            const typePriority = (a.message.type === criticaltype ? -1 : 1) - (b.message.type === criticaltype ? -1 : 1);
            const datePriority = new Date(b.createdAt) - new Date(a.createdAt);
            return typePriority || datePriority;
        })[0] ?? null
);

export default userMessagesSlice.reducer;
