/* eslint-disable no-param-reassign */
import {merge} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';

import {
    getArtifact, getHistories
} from '../api/apaasHistoryThunk';

const setStateField = (state, field, newValue) => {
    state[field] = newValue;
};

const mergeStateField = (state, field, newValue) => {
    setStateField(state, field, merge(state[field], newValue));
};

const setStateAsLoading = (state) => {
    mergeStateField(state, 'status', {loading: true});
};

const setStateAsNotLoading = (state) => {
    mergeStateField(state, 'status', {loading: false});
};

const initialState = {
    error: null,
    status: {
        loading: false
    },
    histories: [],
    historiesCount: 0,
    artifact: null
};

const apaasHistoriesSlice = createSlice({
    name: 'apaasHistories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHistories.fulfilled, (state, action) => {
                setStateAsNotLoading(state);
                setStateField(state, 'histories', action.payload.rows);
                setStateField(state, 'historiesCount', action.payload.count);
            })
            .addCase(getArtifact.fulfilled, (state, action) => {
                setStateAsNotLoading(state);
                setStateField(state, 'artifact', action.payload.data);
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('apaasHistories'), (state) => {
                setStateAsLoading(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('apaasHistories'), (state, action) => {
                setStateAsNotLoading(state);
                setStateField(state, 'error', action.error.message);
            });
    }
});

export const {setOverridesPageCache, resetState} = apaasHistoriesSlice.actions;
export default apaasHistoriesSlice.reducer;
