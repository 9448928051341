import lodash from 'lodash';
import {Store} from 'flux/utils';

import Dispatcher from '../core/Dispatcher';
import ActionTypes from '../constants/ActionTypes';

let __error = null;
let __status = {loading: false};
let __data = {};

class UploadManagerStore extends Store {
    getError() {
        return __error;
    }

    isLoading() {
        return __status.loading;
    }

    getInstances() {
        return lodash.omit(__data, ['status', 'files', 'current']);
    }

    getUploadStatus(type) {
        return lodash.get(__data, ['status', type]);
    }

    isUploading(type) {
        return lodash.get(__data, ['isUploading', type]);
    }

    getWaitingFile() {
        return lodash.get(__data, 'files', []).pop();
    }

    hasWaitingFiles() {
        return lodash.get(__data, 'files', []).length > 0;
    }

    getCurrentUploadAppId(type) {
        return this.isUploading(type) ? this.getCurrentUploadData(type).id : null;
    }

    getCurrentUploadData(type) {
        return lodash.get(__data, `current.${type}`, {});
    }

    __setState(data, error, status) {
        __data = lodash.assign(__data || {}, data);
        __status = lodash.merge(__status || {}, status);
        __error = error;

        this.__emitChange();
    }

    __clear() {
        __error = null;
        __status = {loading: false};
        __data = {};
    }

    __onDispatch(payload) {
        switch (payload.actionType) {
            // subscribing
            case ActionTypes.UPLOAD_MANAGER_SUBSCRIBE_START:
                this.__setState(null, null, {loading: true});
                break;
            case ActionTypes.UPLOAD_MANAGER_SUBSCRIBE_SUCCESS: {
                let conf = lodash.set({}, payload.data.type, payload.data.props);

                if (!lodash.isEmpty(lodash.get(payload.data, 'props.fileData'))) {
                    conf = lodash.assign({}, conf, lodash.set({}, `current.${payload.data.type}`, lodash.get(payload.data.props, 'fileData')));
                }

                this.__setState(conf, null, {loading: false});
                break;
            }
            // unsubscibing
            case ActionTypes.UPLOAD_MANAGER_UNSUBSCRIBE_START:
                // this.__setState({}, null, {loading: true});
                break;
            case ActionTypes.UPLOAD_MANAGER_UNSUBSCRIBE_SUCCESS:
                // this.__setState({uploadResult: payload.data},  null, {loading: false});
                break;

            // status changed
            case ActionTypes.UPLOAD_MANAGER_STATUS_CHANGE_START:
                this.__setState({status: null}, null, {loading: true});
                break;
            case ActionTypes.UPLOAD_MANAGER_STATUS_CHANGE_SUCCESS: {
                let state = lodash.set({}, ['status', payload.data.type], lodash.omit(payload.data, ['type', 'isUploading']));

                if (lodash.isBoolean(payload.data.isUploading)) {
                    state = {...state, ...lodash.set({}, ['isUploading', payload.data.type], payload.data.isUploading)};
                }

                this.__setState(state, null, {loading: false});
                break;
            }
            default:
                break;
        }
    }
}

const instance = new UploadManagerStore(Dispatcher);
export default instance;
