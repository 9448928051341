import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getPackages = createAsyncThunk('/maliciousPackages/getPackages',
    async (params) => fetchRequest('/malicious-packages', 'GET', params));

export const createPackage = createAsyncThunk('/maliciousPackages/createPackage',
    async (body) => fetchRequest('/malicious-packages', 'POST', null, body));

export const updatePackage = createAsyncThunk('/maliciousPackages/updatePackage',
    async (params, thunkAPI) => {
        const {queryParams, id, ...data} = params;
        await fetchRequest(`/malicious-packages/${id}`, 'PUT', id, data);
        thunkAPI.dispatch(getPackages(queryParams));
    });

export const deletePackage = createAsyncThunk('/maliciousPackages/deletePackage',
    async (params, thunkAPI) => {
        const {queryParams, id} = params;
        await fetchRequest(`/malicious-packages/${id}`, 'DELETE', null);
        thunkAPI.dispatch(getPackages(queryParams));
    });

export const getOverrides = createAsyncThunk('/maliciousPackages/getOverrides',
    async (params) => fetchRequest('/malicious-packages/overrides', 'GET', params));

export const createOverride = createAsyncThunk('/maliciousPackages/createOverride',
    async (params, thunkAPI) => {
        const {newPackage, ...queryParams} = params;
        await fetchRequest('/malicious-packages/overrides', 'POST', queryParams.companyId ? {companyId: queryParams.companyId} : null, newPackage);
        thunkAPI.dispatch(getOverrides(queryParams));
    });

export const deleteOverride = createAsyncThunk('/maliciousPackages/deleteOverride',
    async (params, thunkAPI) => {
        const {id, ...queryParams} = params;
        await fetchRequest(`/malicious-packages/overrides/${id}`, 'DELETE', queryParams.companyId ? {companyId: queryParams.companyId} : null, null);
        thunkAPI.dispatch(getOverrides(queryParams));
    });
