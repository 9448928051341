/* eslint-disable no-param-reassign */
import lodash from 'lodash';
import {createSlice} from '@reduxjs/toolkit';

import {
    getAllowedCountries, addCountry, deleteCountry
} from '../api/allowedCountriesThunk';

const setPendingState = (state) => {
    const status = {
        loading: true
    };
    state.status = lodash.merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false
    };
    state.status = lodash.merge(state.status, status);
    state.error = action.error.message;
};

const setGetFulfilledState = (state, action) => {
    const status = {
        loading: false,
        added: false,
        deleted: false
    };
    state.status = lodash.merge(state.status, status);
    state.allowedCountries = action.payload ?? 'An error has occurred';
};

const initialState = {
    error: null,
    status: {
        loading: false,
        added: false,
        deleted: false
    },
    allowedCountries: []
};

const allowedCountriesSlice = createSlice({
    name: 'allowedCountries',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllowedCountries.fulfilled, (state, action) => {
                setGetFulfilledState(state, action);
            })
            .addCase(addCountry.fulfilled, (state) => {
                state.status.loading = false;
                state.status.added = true;
            })
            .addCase(deleteCountry.fulfilled, (state) => {
                state.status.loading = false;
                state.status.deleted = true;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('allowedCountries'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('allowedCountries'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export default allowedCountriesSlice.reducer;
