/* eslint-disable no-param-reassign */
import lodash from 'lodash';
import {createSlice} from '@reduxjs/toolkit';

import getProducts from '../api/productsThunk';

const setPendingState = (state) => {
    const status = {
        loading: true
    };
    state.status = lodash.merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false
    };
    state.status = lodash.merge(state.status, status);
    state.error = action.error.message;
};

const setGetFulfilledState = (state, action) => {
    const status = {
        loading: false
    };
    state.status = lodash.merge(state.status, status);
    state.products = action.payload;
};


const initialState = {
    error: null,
    status: {
        loading: false,
        updated: false
    },
    products: []
};

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.fulfilled, (state, action) => {
                setGetFulfilledState(state, action);
            })
            .addCase(getProducts.pending, (state) => {
                setPendingState(state);
            })
            .addCase(getProducts.rejected, (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export default productsSlice.reducer;
