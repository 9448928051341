/* eslint-disable no-param-reassign */
import {merge, concat} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';
import dataFieldProperties from '../components/AppAware/AppAwareEventsDetails/data_field_properties.json';

import {
    getEvents
} from '../api/appAwareEventsThunk';

const setRejectedState = (state, action) => {
    const status = {
        loading: false,
        firstLoading: false
    };
    state.status = merge(state.status, status);
    state.error = action.error.message;
};

const setGetFulfilledState = (state) => {
    const status = {
        loading: false,
        firstLoading: false
    };
    state.status = merge(state.status, status);
};

const initialState = {
    error: null,
    status: {
        loading: false,
        firstLoading: false
    },
    definitions: {},
    events: [],
    meta: {},
    areEventsLeft: true
};

const mapEventDataArray = (event) => ({
    ...event,
    data: event.data.map(({key, value}) => {
        let dataKey = dataFieldProperties.find(({label}) => label === key)?.key;
        if (dataKey) {
            dataKey = dataKey.replace('data.', '');
        }
        return {
            data_key: dataKey,
            data_value: value
        };
    })
});

const appAwareEventsSlice = createSlice({
    name: 'appAwareEvents',
    initialState,
    reducers: {
        resetEvents: (state) => {
            state.events = [];
            state.areEventsLeft = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEvents.fulfilled, (state, action) => {
                setGetFulfilledState(state);
                state.areEventsLeft = !(action.payload.data.length < action.meta.arg.limit);
                state.events = concat(
                    state.events.map((event) => ({...event, new: false})),
                    action.payload.data.map((event) => ({...mapEventDataArray(event), new: true}))
                );
                state.meta = action.payload.meta;
                state.definitions = action.payload.definitions;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('appAwareEvents'), (state) => {
                state.error = null;
                state.status.loading = true;
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('appAwareEvents'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export const {resetEvents} = appAwareEventsSlice.actions;
export default appAwareEventsSlice.reducer;
