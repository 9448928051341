/* eslint-disable no-param-reassign */
import {isNil, merge} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';
import {getFeatures, toggleAppAwareFeature, upsertFeature} from '../api/appFeatureThunk';

const setPendingState = (state) => {
    const status = {
        loading: true,
        updated: false
    };
    state.status = merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false,
        updated: false
    };
    state.status = merge(state.status, status);
    state.error = action.error.message;
};

const initialState = {
    error: null,
    status: {
        loading: false,
        updated: false
    },
    lastToggledAppAwareFeatureAppId: null,
    appFeatures: {}
};

const addAppFeatureToState = (feature, state) => {
    const {applicationId, featureType} = feature;

    const existingFeatureForAppId = state.appFeatures[applicationId];
    if (isNil(existingFeatureForAppId)) {
        state.appFeatures[applicationId] = {
            [featureType]: feature
        };
        return;
    }

    state.appFeatures[applicationId][featureType] = feature;
};

const appFeatureSlice = createSlice({
    name: 'appFeatures',
    initialState,
    reducers: {
        removeLastToggledAppAwareFeatureAppId: (state) => {
            state.lastToggledAppAwareFeatureAppId = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFeatures.fulfilled, (state, action) => {
                state.status.loading = false;
                state.status.updated = false;
                action.payload.rows.forEach((feature) => addAppFeatureToState(feature, state));
            })
            .addCase(upsertFeature.fulfilled, (state, action) => {
                state.status.loading = false;
                state.status.updated = true;
                addAppFeatureToState(action.payload, state);
            })
            .addCase(toggleAppAwareFeature.fulfilled, (state, action) => {
                state.status.loading = false;
                state.status.updated = true;
                state.lastToggledAppAwareFeatureAppId = action.payload.applicationId;
                addAppFeatureToState(action.payload, state);
            })
            .addMatcher((action) => action.type.endsWith('/fulfilled') && action.type.includes('appFeatures'), (state) => {
                state.status.loading = false;
                state.status.updated = true;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('appFeatures'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('appFeatures'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export const {removeLastToggledAppAwareFeatureAppId} = appFeatureSlice.actions;
export default appFeatureSlice.reducer;
