import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getContracts = createAsyncThunk('contracts/getContracts', async (params) => fetchRequest('/contracts', 'GET', params));

export const updateContract = createAsyncThunk('contracts/updateContract',
    async (body, thunkAPI) => {
        const {id, ...data} = body;
        await fetchRequest(`/contracts/${id}`, 'PUT', null, data);
        thunkAPI.dispatch(getContracts());
    });
