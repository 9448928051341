let __token = null;
const __tokenKey = 'token';

const get = () => {
    __token = __token || localStorage.getItem(__tokenKey);
    return __token;
};

const apply = (request) => {
    request.set('Authorization', get());
};

const set = (token) => {
    localStorage.setItem(__tokenKey, token);
    __token = token;
};

const remove = () => {
    localStorage.removeItem(__tokenKey);
    __token = null;
};

export default {
    apply,
    get,
    set,
    remove
};
