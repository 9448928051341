/* eslint-disable no-param-reassign */
import {merge, concat} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';

import {
    getAlerts
} from '../api/appAwareAlertsThunk';

const setRejectedState = (state, action) => {
    const status = {
        loading: false,
        firstLoading: false
    };
    state.status = merge(state.status, status);
    state.error = action.error.message;
};

const setGetFulfilledState = (state) => {
    const status = {
        loading: false,
        firstLoading: false
    };
    state.status = merge(state.status, status);
};

const initialState = {
    error: null,
    status: {
        loading: false,
        firstLoading: false
    },
    definitions: {},
    alerts: [],
    areAlertsLeft: true
};

const appAwareAlertsSlice = createSlice({
    name: 'appAwareAlerts',
    initialState,
    reducers: {
        resetAlerts: (state) => {
            state.alerts = [];
            state.areAlertsLeft = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAlerts.fulfilled, (state, action) => {
                setGetFulfilledState(state);
                state.areAlertsLeft = !(action.payload.data.length < action.meta.arg.limit);
                state.alerts = concat(state.alerts.map((alert) => ({...alert, new: false})), action.payload.data.map((alert) => ({...alert, new: true})));
                state.definitions = action.payload.definitions;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('appAwareAlerts'), (state) => {
                state.error = null;
                state.status.loading = true;
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('appAwareAlerts'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export const {resetAlerts} = appAwareAlertsSlice.actions;
export default appAwareAlertsSlice.reducer;
