/* eslint-disable no-param-reassign */
import lodash from 'lodash';
import {createSlice} from '@reduxjs/toolkit';

import {
    getContracts, updateContract
} from '../api/contractsThunk';

const setPendingState = (state) => {
    const status = {
        loading: true,
        updated: false
    };
    state.status = lodash.merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false,
        updated: false
    };
    state.status = lodash.merge(state.status, status);
    state.error = action.error.message;
};

const setGetFulfilledState = (state, action) => {
    const status = {
        loading: false,
        updated: false
    };
    state.status = lodash.merge(state.status, status);
    state.contracts = action.payload;
};

const initialState = {
    error: null,
    status: {
        loading: false,
        updated: false
    },
    contracts: []
};

const contractsSlice = createSlice({
    name: 'contracts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getContracts.fulfilled, (state, action) => {
                setGetFulfilledState(state, action);
            })
            .addCase(updateContract.fulfilled, (state) => {
                state.status.loading = false;
                state.status.updated = true;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('contracts'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('contracts'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export default contractsSlice.reducer;
