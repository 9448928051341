import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getFiles = createAsyncThunk('fileShare/getFiles', async ({companyId}) => fetchRequest(`/share/${companyId}/uploads`, 'GET'));

export const getFile = createAsyncThunk('fileShare/getFile', async ({companyId, uploadId}) => fetchRequest(`/share/${companyId}/uploads/${uploadId}`, 'GET'));

export const deleteFile = createAsyncThunk('fileShare/deleteFile', async ({id, companyId}, thunkAPI) => {
    await fetchRequest(`/share/uploads/${id}`, 'DELETE');
    thunkAPI.dispatch(getFiles({companyId}));
});

export const getDownloadAccessCode = async (id) => fetchRequest(`/downloads/shares/${id}/downloadAccessCode`, 'POST');
