import React from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';

/**
 * Checks if parameter route is availableRoute
 * @param  {string} route          - URI as seen in brower URL bar (eg: /app/1)
 * @param  {string} availableRoute - URI as defined for react-routes (eg: /app/:id)
 * @param  {bool}   [loose]        - loose check if route matches availableRoute
 * @return {bool}                  - true if route is validRoute
 */
const routeMatches = (route, availableRoute, loose = false) => {
    if (loose) {
        return route.startsWith(availableRoute);
    }

    // Strip trailing slash if present and split into parts
    const routeParts = route.replace(/\/$/, '').split('/');
    const validRouteParts = availableRoute.split('/');
    validRouteParts.forEach((part, index) => {
        if (part.startsWith(':')) {
            validRouteParts[index] = routeParts[index];
        }
    });
    return routeParts.join('/').toLowerCase() === validRouteParts.join('/').toLowerCase();
};

/**
 * Checks if given route matches one of availableRoutes
 * @param  {string} route                  - route to validate
 * @param  {Array<string>} availableRoutes - routes to match against (supports parameter placeholders)
 * @return {bool}                          - true if routes matches one of availableRoutes
 */
const routeValidator = (route, availableRoutes) => {
    // root route is always accessible for everyone
    if (route === '/') {
        return true;
    }

    const rv = availableRoutes.reduce((acc, value) => {
        if (acc) {
            return acc;
        }
        return routeMatches(route, value);
    }, false);

    return rv;
};

export {routeMatches};
export default routeValidator;

export const RouteDynamicParamValidation = ({
    element, allowedValues, paramName
}) => {
    const params = useParams();
    const param = params[paramName];

    return allowedValues.includes(param) ? element : <NotFoundPage/>;
};

RouteDynamicParamValidation.propTypes = {
    element: PropTypes.element,
    allowedValues: PropTypes.arrayOf(PropTypes.string),
    paramName: PropTypes.string
};
