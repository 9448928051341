/* eslint-disable no-param-reassign */
import {merge} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';

import {
    getClients, getClient, createClient, updateClient, deleteClient
} from '../api/oauthThunk';

const setPendingState = (state) => {
    const status = {
        loading: true,
        deleted: false,
        created: false,
        updated: false
    };
    state.status = merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false,
        deleted: false,
        created: false,
        updated: false
    };
    state.status = merge(state.status, status);
    state.error = true;
    state.errorMessage = action.error.message;
};

const setFulfilledState = (state) => {
    const status = {
        loading: false,
        deleted: false,
        created: false,
        updated: false
    };
    state.status = merge(state.status, status);
    state.error = false;
    state.errorMessage = null;
};

const initialState = {
    error: null,
    errorMessage: null,
    status: {
        loading: false,
        deleted: false,
        created: false,
        updated: false
    },
    selected: null,
    data: []
};

const oauthSlice = createSlice({
    name: 'oauth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getClients.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.data = action.payload;
            })
            .addCase(getClient.fulfilled, (state, action) => {
                setFulfilledState(state);
                state.selected = action.payload;
            })
            .addCase(createClient.fulfilled, (state) => {
                setFulfilledState(state);
                state.status.created = true;
            })
            .addCase(updateClient.fulfilled, (state) => {
                setFulfilledState(state);
                state.created = null;
                state.selected = null;
                state.status.updated = true;
            })
            .addCase(deleteClient.fulfilled, (state) => {
                setFulfilledState(state);
                state.created = null;
                state.selected = null;
                state.status.deleted = true;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('oauth'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('oauth'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export default oauthSlice.reducer;
