/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit';

import {
    getLicense, getLicenses, updateLicense, createLicense, regenerateOfflineLicense, deleteLicense
} from '../api/licensesThunk';

const setFulfilledStatusState = (state) => {
    state.status = {
        loading: false,
        created: false,
        updated: false,
        deleted: false
    };
    state.error = null;
};

const initialState = {
    error: null,
    status: {
        loading: false,
        created: false,
        updated: false,
        deleted: false
    },
    licenses: [],
    license: {}
};

const licensesSlice = createSlice({
    name: 'licenses',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLicense.fulfilled, (state, action) => {
                setFulfilledStatusState(state);
                state.license = action.payload;
            })
            .addCase(getLicenses.fulfilled, (state, action) => {
                setFulfilledStatusState(state);
                state.licenses = action.payload;
            })
            .addCase(deleteLicense.fulfilled, (state) => {
                state.status.loading = false;
                state.status.created = false;
                state.status.deleted = true;
                state.status.updated = false;
                state.error = null;
            })
            .addCase(regenerateOfflineLicense.fulfilled, (state) => {
                state.status.loading = false;
                state.status.created = false;
                state.status.deleted = false;
                state.status.updated = true;
                state.error = null;
            })
            .addCase(updateLicense.fulfilled, (state) => {
                state.status.loading = false;
                state.status.created = false;
                state.status.deleted = false;
                state.status.updated = true;
                state.error = null;
            })
            .addCase(createLicense.fulfilled, (state) => {
                state.status.loading = false;
                state.status.created = true;
                state.status.updated = false;
                state.status.deleted = false;
                state.error = null;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('licenses'), (state) => {
                state.status.loading = true;
                state.status.created = false;
                state.status.updated = false;
                state.status.deleted = false;
                state.error = null;
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('licenses'), (state, action) => {
                state.error = action.error.message;
            });
    }
});

export default licensesSlice.reducer;
