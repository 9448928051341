import {createAsyncThunk} from '@reduxjs/toolkit';
import omitEmpty from '../utils/omitEmpty';
import fetchRequest from './utils';

// Disabling the rule in case we need to add another thunk there is no need to change anything
// eslint-disable-next-line import/prefer-default-export
export const getEvents = createAsyncThunk('appAwareEvents/getEvents', async ({
    companyId,
    limit,
    page,
    filter
}) => {
    const query = omitEmpty({
        companyId,
        limit,
        page
    });
    return fetchRequest('/threats/table', 'POST', query, {filter});
});
