import React from 'react';
import PropTypes from 'prop-types';
import withRouter from '../../utils/withRouterWrapper';

import GA from '../../utils/GA';

import getTitle from '../../utils/title';

import {
    StyledMetaDataManagerDiv
} from './MetaDataManager.styles';

class MetaDataManager extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        location: PropTypes.object
    };

    static defaultProps = {
        children: undefined,
        location: {}
    }

    constructor(props) {
        super(props);
        GA.create();
    }

    componentDidMount() {
        this.pageTitle = getTitle(this.props.location);
    }

    shouldComponentUpdate(nextProps) {
        return this.props.location.pathname !== nextProps.location.pathname;
    }

    componentDidUpdate() {
        this.pageTitle = getTitle(this.props.location);
    }

    set pageTitle(title) {
        document.title = title;
        GA.sendPageView(this.props.location.pathname);
    }

    render() {
        return (
            <StyledMetaDataManagerDiv className="MetaDataManager">
                {this.props.children}
            </StyledMetaDataManagerDiv>
        );
    }
}

export default withRouter(MetaDataManager);
