/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit';
import {merge} from 'lodash';
import {
    getProtectionHistory, getAppNames, getProtectionHistoryStatus
} from '../api/protectionHistoryThunk';

const setPendingState = (state) => {
    const status = {
        loading: true
    };
    state.status = merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false
    };
    state.status = merge(state.status, status);
    state.error = action.error.message;
};

const setFulfilledState = (state) => {
    const status = {
        loading: false
    };
    state.status = merge(state.status, status);
};

const initialState = {
    error: null,
    status: {
        loading: false
    },
    protectionHistoryStatus: null,
    appNames: [],
    protectionHistory: null
};

const protectionHistorySlice = createSlice({
    name: 'protectionHistory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAppNames.fulfilled, (state, action) => {
                state.appNames = action.payload;
                setFulfilledState(state);
            })
            .addCase(getProtectionHistory.fulfilled, (state, action) => {
                state.protectionHistory = action.payload;
                setFulfilledState(state);
            })
            .addCase(getProtectionHistoryStatus.fulfilled, (state, action) => {
                state.protectionHistoryStatus = action.payload;
                setFulfilledState(state);
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('protectionHistory'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('protectionHistory'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export default protectionHistorySlice.reducer;
