import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getMessages = createAsyncThunk('userMessages/getMessages', async (params) => fetchRequest('/me/messages', 'GET', params));

export const markMessageRead = createAsyncThunk(
    'userMessages/markMessageRead',
    async (id, thunkAPI) => {
        await fetchRequest(`/me/messages/${id}`, 'PATCH');
        thunkAPI.dispatch(getMessages());
    }
);

export const deleteMessage = createAsyncThunk(
    'userMessages/deleteMessage',
    async (id, thunkAPI) => {
        await fetchRequest(`/me/messages/${id}`, 'DELETE');
        thunkAPI.dispatch(getMessages());
    }
);
