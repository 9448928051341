import GlobalConst from '../constants/global';
import token from './token';

const getBaseUrl = (path) => `${GlobalConst.ADP_API_URL}${path}`;

const addParamsToUrl = (url, params) => `${url}?${new URLSearchParams({...params})}`;

export const SESSION_EXPIRED_ERROR_MESSAGE = 'Your session has expired. Please log-in again';

export const fetchRequestFromUrl = async (url, method, params = null, body = null, unauthenticated = false, authentication = null) => {
    const request = params ? addParamsToUrl(url, params) : url;
    const GENERIC_ERROR = 'A server error occurred. Please refresh the page.';
    let authToken = token.get();
    if (authentication) {
        authToken = authentication;
    }
    const headers = {
        ...(authToken && !unauthenticated ? {Authorization: authToken} : {}),
        'Content-Type': 'application/json'
    };
    const response = await fetch(request, {
        method,
        headers,
        body: body ? JSON.stringify(body) : null
    });

    if (!response.ok) {
        let errorMessage = null;
        if (!unauthenticated && response.status === 401) {
            throw new Error(SESSION_EXPIRED_ERROR_MESSAGE);
        }
        try {
            const errorResponse = await response.json();
            errorMessage = errorResponse.message || errorResponse?.currentPassword || errorResponse?.createPassword;
        } catch (error) {
            errorMessage = response.statusText;
        }
        if (!errorMessage) {
            errorMessage = GENERIC_ERROR;
        }
        throw new Error(errorMessage);
    }

    try {
        const text = await response.text();
        return text.length ? JSON.parse(text) : {};
    } catch (error) {
        throw new Error(GENERIC_ERROR);
    }
};

const fetchRequest = async (
    url,
    method,
    params = null,
    body = null,
    unauthenticated = false,
    authentication = null
) => fetchRequestFromUrl(getBaseUrl(url), method, params, body, unauthenticated, authentication);

export default fetchRequest;
