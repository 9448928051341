import React from 'react';
import {matchPath} from 'react-router-dom';
import {DotIcon, DotTypography} from '@digital-ai/dot-components';
import {
    StyledMenuElementDiv,
    StyledMenuElementLeftIconDiv,
    StyledMenuElementTitleDiv,
    StyledMenuElementRightIconDiv
} from './Navigation.styles';

import {
    ROLES, ROUTES, BREADCRUMB_ROOT
} from '../../constants/global';

export const getBreadcrumb = ({pathname}) => {
    const breadcrumbs = [{
        text: BREADCRUMB_ROOT,
        underline: 'always',
        ariaLabel: BREADCRUMB_ROOT,
        href: '/'
    }];

    const routeDetails = ROUTES[Object.keys(ROUTES).find((key) => matchPath(ROUTES[key].route, pathname))];
    routeDetails?.crumbs.forEach((crumb) => breadcrumbs.push({
        text: crumb.text || crumb,
        underline: 'always',
        ariaLabel: crumb.text || crumb,
        href: ROUTES[crumb?.routeName]?.route
    }));

    if (routeDetails?.title) {
        breadcrumbs.push({
            text: routeDetails.title,
            ariaLabel: routeDetails.title
        });
    }

    return breadcrumbs;
};

export const getToolbarMenus = ({
    unviewedMessages,
    role,
    isKeycloakAuthenticated,
    handleResourceToggle,
    navigate,
    handleAppSwitcherToggle
}) => {
    const items = [
        {
            iconId: 'help',
            tooltip: 'Resources',
            onClick: (event) => handleResourceToggle(event)
        }
    ];

    if ([ROLES.accountOwner, ROLES.user].includes(role)) {
        items.push({
            displayBadge: !!unviewedMessages,
            badgeContent: unviewedMessages,
            badgeVariant: 'standard',
            iconId: 'notification-bell',
            tooltip: 'Notifications',
            className: 'notifications',
            onClick: () => navigate(ROUTES.notifications.route)
        });
    }

    if (ROLES.admin !== role && isKeycloakAuthenticated) {
        items.push({
            iconId: 'apps',
            onClick: handleAppSwitcherToggle,
            className: 'dot-app-switcher-nav-item',
            tooltip: 'Applications'
        });
    }

    return items;
};

const getMenuItemElement = (title, iconId, testId, newTab = false) => (
    <StyledMenuElementDiv data-testid={`navigation__${testId}`} >
        <StyledMenuElementLeftIconDiv>
            <DotIcon iconId={iconId} />
        </StyledMenuElementLeftIconDiv>
        <StyledMenuElementTitleDiv>
            <DotTypography variant="body1">{title}</DotTypography>
        </StyledMenuElementTitleDiv>
        <StyledMenuElementRightIconDiv>
            {newTab && <DotIcon iconId="open-new-tab" />}
        </StyledMenuElementRightIconDiv>
    </StyledMenuElementDiv>
);

export const resourceMenuItems = [
    {
        children: getMenuItemElement('Support', 'help', 'support-menu-item', true),
        key: 'support'
    },
    {
        children: getMenuItemElement('Community', 'learn', 'community-menu-item', true),
        key: 'community'
    },
    {
        children: getMenuItemElement('Documentation', 'file-lines', 'documentation-menu-item', true),
        key: 'documentation'
    }
];

export const profileMenuItems = [
    {
        children: getMenuItemElement('View profile', 'user', 'profile-menu-item'),
        key: 'profile'
    },
    {
        children: getMenuItemElement('Logout', 'exit', 'logout-menu-item'),
        key: 'logout'
    }
];

export const MENUS_KEYS = {
    ROOT: 'ROOT',
    PROTECT: 'PROTECT',
    ANALYZE: 'ANALYZE',
    MANAGE: 'MANAGE'
};

export const MENUS = {
    [MENUS_KEYS.ROOT]: {
        backItem: null,
        items: [
            {
                roles: [ROLES.admin, ROLES.accountOwner, ROLES.user, ROLES.securityResearcher],
                section: null,
                text: 'Home',
                startIcon: 'home',
                route: ROUTES.home,
                matchingRoutes: [
                    ROUTES.home
                ]
            },
            {
                roles: [ROLES.admin, ROLES.accountOwner, ROLES.user],
                section: null,
                text: 'Protect',
                startIcon: 'security-on',
                endIcon: 'chevron-right',
                goto: MENUS_KEYS.PROTECT
            },
            {
                roles: [ROLES.admin, ROLES.accountOwner, ROLES.user],
                section: null,
                text: 'Analyze',
                startIcon: 'dashboard',
                endIcon: 'chevron-right',
                goto: MENUS_KEYS.ANALYZE
            },
            {
                roles: [ROLES.admin, ROLES.accountOwner, ROLES.user, ROLES.securityResearcher],
                section: null,
                text: 'Manage',
                startIcon: 'settings',
                endIcon: 'chevron-right',
                goto: MENUS_KEYS.MANAGE
            }
        ]
    },
    [MENUS_KEYS.PROTECT]: {
        backItem: {
            text: 'Protect',
            goto: MENUS_KEYS.ROOT
        },
        items: [
            {
                roles: [ROLES.admin],
                section: 'ON-PREMISE',
                text: 'Releases',
                startIcon: 'rocket',
                route: ROUTES.releases,
                matchingRoutes: [
                    ROUTES.releases
                ]
            },
            {
                roles: [ROLES.accountOwner, ROLES.user],
                section: 'ON-PREMISE',
                text: 'Product download',
                startIcon: 'download',
                route: ROUTES.productDownload,
                matchingRoutes: [
                    ROUTES.productDownload
                ]
            },
            {
                roles: [ROLES.admin, ROLES.accountOwner, ROLES.user],
                section: 'ON-PREMISE',
                text: 'Key & Data',
                startIcon: 'key',
                route: ROUTES.keyAndData,
                matchingRoutes: [
                    ROUTES.keyAndData
                ]
            }
        ]
    },
    [MENUS_KEYS.ANALYZE]: {
        backItem: {
            text: 'Analyze',
            goto: MENUS_KEYS.ROOT
        },
        items: [
            {
                roles: [ROLES.admin, ROLES.accountOwner, ROLES.user],
                section: 'APP AWARE',
                text: 'Dashboard',
                startIcon: 'report',
                route: ROUTES.appAwareDashboard,
                matchingRoutes: [
                    ROUTES.appAwareDashboard
                ]
            },
            {
                roles: [ROLES.admin, ROLES.accountOwner, ROLES.user],
                section: 'APP AWARE',
                text: 'Alerts',
                startIcon: 'warning-outline',
                route: ROUTES.appAwareAlerts,
                matchingRoutes: [
                    ROUTES.appAwareAlerts
                ]
            },
            {
                roles: [ROLES.admin, ROLES.accountOwner, ROLES.user],
                section: 'APP AWARE',
                text: 'Events',
                startIcon: 'table',
                route: ROUTES.appAwareEvents,
                matchingRoutes: [
                    ROUTES.appAwareEvents
                ]
            },
            {
                roles: [ROLES.admin, ROLES.accountOwner, ROLES.user],
                section: 'LOGS',
                text: 'Protection history',
                startIcon: 'file-lines',
                route: ROUTES.protectionHistory,
                matchingRoutes: [
                    ROUTES.protectionHistory
                ]
            }
        ]
    },
    [MENUS_KEYS.MANAGE]: {
        backItem: {
            text: 'Manage',
            goto: MENUS_KEYS.ROOT
        },
        items: [
            {
                roles: [ROLES.accountOwner, ROLES.user],
                section: 'SETUP',
                text: 'API credentials',
                startIcon: 'cloud',
                route: ROUTES.manageApiCredentials,
                matchingRoutes: [
                    ROUTES.manageApiCredentials
                ]
            },
            {
                roles: [ROLES.accountOwner, ROLES.user],
                section: 'SETUP',
                text: 'App Aware',
                startIcon: 'monitor-gears',
                route: ROUTES.manageAppAwareSetup,
                matchingRoutes: [
                    ROUTES.manageAppAwareSetup
                ]
            },
            {
                roles: [ROLES.accountOwner, ROLES.user],
                section: 'SETUP',
                text: 'License tokens',
                startIcon: 'dictionary-locked',
                route: ROUTES.manageLicenseTokens,
                matchingRoutes: [
                    ROUTES.manageLicenseTokens
                ]
            },
            {
                roles: [ROLES.accountOwner, ROLES.user],
                section: 'SYSTEM',
                text: 'Malicious packages',
                startIcon: 'flag',
                route: ROUTES.manageMaliciousPackageExclusions,
                matchingRoutes: [
                    ROUTES.manageMaliciousPackageExclusions
                ]
            },
            {
                roles: [ROLES.accountOwner],
                section: 'SETUP',
                text: 'Team',
                startIcon: 'users',
                route: ROUTES.manageTeam,
                matchingRoutes: [
                    ROUTES.manageTeam
                ]
            },
            {
                roles: [ROLES.accountOwner, ROLES.user, ROLES.admin],
                section: 'SUPPORT',
                text: 'File share',
                startIcon: 'upload-file',
                route: ROUTES.manageFileShare,
                matchingRoutes: [
                    ROUTES.manageFileShare
                ]
            },
            {
                roles: [ROLES.admin],
                section: 'SUPPORT',
                text: 'Notifications',
                startIcon: 'announcement',
                route: ROUTES.manageNotifications,
                matchingRoutes: [
                    ROUTES.manageNotifications,
                    ROUTES.manageNotificationsCreate,
                    ROUTES.manageNotificationCreateById
                ]
            },
            {
                roles: [ROLES.admin],
                section: 'SYSTEM',
                text: 'Allowed countries',
                startIcon: 'location',
                route: ROUTES.manageAllowedCountries,
                matchingRoutes: [
                    ROUTES.manageAllowedCountries
                ]
            },
            {
                roles: [ROLES.admin],
                section: 'SYSTEM',
                text: 'App Aware',
                startIcon: 'monitor-gears',
                route: ROUTES.supportAppAwareSetup,
                matchingRoutes: [
                    ROUTES.supportAppAwareSetup
                ]
            },
            {
                roles: [ROLES.admin],
                section: 'SYSTEM',
                text: 'Companies',
                startIcon: 'briefcase',
                route: ROUTES.manageCompanies,
                matchingRoutes: [
                    ROUTES.manageCompanies
                ]
            },
            {
                roles: [ROLES.admin],
                section: 'SYSTEM',
                text: 'Contracts',
                startIcon: 'file-circle-check',
                route: ROUTES.manageContracts,
                matchingRoutes: [
                    ROUTES.manageContracts
                ]
            },
            {
                roles: [ROLES.user, ROLES.accountOwner, ROLES.admin],
                section: 'SYSTEM',
                text: 'Licenses',
                startIcon: 'dictionary-locked',
                route: ROUTES.manageLicenses,
                matchingRoutes: [
                    ROUTES.manageLicenses,
                    ROUTES.manageLicensesById
                ]
            },
            {
                roles: [ROLES.admin, ROLES.securityResearcher],
                section: 'SYSTEM',
                text: 'Malicious packages',
                startIcon: 'flag-risk',
                route: ROUTES.manageMaliciousPackages,
                matchingRoutes: [
                    ROUTES.manageMaliciousPackages
                ]
            },
            {
                roles: [ROLES.admin],
                section: 'SYSTEM',
                text: 'Users',
                startIcon: 'users',
                route: ROUTES.manageUsers,
                matchingRoutes: [
                    ROUTES.manageUsers
                ]
            }
        ]
    }
};

export const isMenuItemActive = (item, pathname) => {
    let path = pathname;
    if (pathname.endsWith('/')) {
        path = pathname.slice(0, -1);
    }
    return item.matchingRoutes?.some((route) => route?.regex?.test(path) ?? route.route === path);
};

export const getActiveMenu = (pathname) => {
    let menuKey = MENUS_KEYS.ROOT;

    for (const key of Object.values(MENUS_KEYS)) {
        const hasActiveItem = MENUS[key].items.some((item) => isMenuItemActive(item, pathname));
        if (hasActiveItem) {
            menuKey = key;
            break;
        }
    }

    return menuKey;
};
