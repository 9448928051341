import {matchPath} from 'react-router-dom';
import {TITLES, ROUTES} from '../constants/global';

/**
 * Returns a title based on current route
 * @param  {Object}  location - location (usually, when used in component - this.props.location)
 * @param  {Function} tr      - translation function
 * @param  {Boolean} page     - If true title suffix won't be applied
 * @return {string}           - page title
 */
export default function getTitle(location, page = false) {
    let title = ROUTES[Object.keys(ROUTES).find((key) => matchPath(ROUTES[key].route, location.pathname))]?.title;
    if (title) {
        if (!page) {
            const suffix = TITLES.SUFFIX;
            title = `${title}${suffix}`;
        }
    } else if (!page) {
        title = TITLES.DEFAULT;
    }

    return title;
}
