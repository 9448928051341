import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getReleases = createAsyncThunk(
    '/release/getReleases',
    async (params) => fetchRequest('/releases', 'GET', params)
);

export const getRelease = createAsyncThunk(
    '/release/getRelease',
    async (releaseId) => fetchRequest(`/releases/${releaseId}`, 'GET', null)
);

export const getUserReleases = createAsyncThunk(
    '/release/getUserReleases',
    async ({applicationId, productId}) => fetchRequest(`/applications/${applicationId}/products/${productId}/releases`, 'GET', null)
);

export const getUserReleasesByProductKey = createAsyncThunk(
    '/release/getUserReleasesByProductKey',
    async (productKey) => fetchRequest(`/me/releases/${productKey}`, 'GET', {key: productKey})
);

export const createRelease = createAsyncThunk(
    '/release/createRelease',
    async (body, thunkAPI) => {
        const release = await fetchRequest('/releases', 'POST', null, body);
        thunkAPI.dispatch(getRelease(release.id));
    }
);

export const deleteRelease = createAsyncThunk(
    '/release/deleteRelease',
    async (releaseId, thunkAPI) => {
        await fetchRequest(`/releases/${releaseId}`, 'DELETE', null);
        thunkAPI.dispatch(getReleases());
    }
);

export const updateRelease = createAsyncThunk(
    '/release/updateRelease',
    async (params, thunkAPI) => {
        const {id} = params;
        await fetchRequest(`/releases/${id}`, 'PUT', null, params);
        thunkAPI.dispatch(getRelease(id));
    }
);

export const getReleaseUploads = createAsyncThunk(
    'release/getReleaseUploads',
    async (releaseId) => fetchRequest(
        `/releases/${releaseId}/uploads`,
        'GET'
    )
);

export const deleteReleaseUpload = createAsyncThunk(
    'release/deleteReleaseUpload',
    async ({releaseId, uploadId}, thunkAPI) => {
        await fetchRequest(
            `/releases/${releaseId}/uploads/${uploadId}`,
            'DELETE'
        );
        thunkAPI.dispatch(getReleaseUploads(releaseId));
    }
);

export const getDownloadAccessCode = async ({releaseId, uploadId}) => fetchRequest(`/downloads/releases/${releaseId}/uploads/${uploadId}/downloadAccessCode`, 'POST');
