import {DotProgress} from '@digital-ai/dot-components';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import FadeInTransition from '../FadeInTransition';

import {
    LoaderDiv
} from './Loader.styles';

class Loader extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        children: PropTypes.any,
        className: PropTypes.string
    };

    static defaultProps = {
        loading: false,
        children: undefined,
        className: ''
    }

    renderContent() {
        return (<FadeInTransition data-testid="loader__progress2">{this.props.children}</FadeInTransition>);
    }

    render() {
        return <>
            {this.props.loading
                ? <LoaderDiv data-testid="loader__wrapper">
                    <DotProgress color='primary' variant='indeterminate' data-testid="loader__progress" ariaLabel='Loading' />
                </LoaderDiv> : this.renderContent()}
        </>;
    }
}

export default Loader;
