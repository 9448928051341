/* eslint-disable no-param-reassign */
import {merge} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';

import {
    getFiles, getFile, deleteFile
} from '../api/fileShareThunk';

const setStateField = (state, field, newValue) => {
    state[field] = newValue;
};

const mergeStateField = (state, field, newValue) => {
    setStateField(state, field, merge(state[field], newValue));
};

const setStateAsDeleted = (state) => {
    mergeStateField(state, 'status', {deleted: true});
};

const setStateAsNotDeleted = (state) => {
    mergeStateField(state, 'status', {deleted: false});
};

const setStateAsLoading = (state) => {
    mergeStateField(state, 'status', {loading: true});
};

const setStateAsNotLoading = (state) => {
    mergeStateField(state, 'status', {loading: false});
};

const initialState = {
    error: null,
    status: {
        loading: false,
        deleted: false
    },
    files: [],
    file: []
};

const fileShareSlice = createSlice({
    name: 'fileShare',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFiles.fulfilled, (state, action) => {
                setStateAsNotLoading(state);
                setStateField(state, 'files', action.payload);
            })
            .addCase(getFile.fulfilled, (state, action) => {
                setStateAsNotLoading(state);
                setStateField(state, 'file', action.payload);
            })
            .addCase(deleteFile.fulfilled, (state) => {
                setStateAsNotLoading(state);
                setStateAsDeleted(state);
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('fileShare'), (state) => {
                setStateAsLoading(state);
                setStateAsNotDeleted(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('fileShare'), (state, action) => {
                setStateAsNotLoading(state);
                setStateAsNotDeleted(state);
                setStateField(state, 'error', action.error.message);
            });
    }
});

export default fileShareSlice.reducer;
