import styled from 'styled-components';

export const StyledNotFoundContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  & svg {
    width: 100%;
  }
`;

export const StyledNotFoundText = styled.section`
    min-width: 340px;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 0.5rem;
`;
